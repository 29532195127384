import React from "react";
import YouTubeIcon from '@mui/icons-material/YouTube';

import "./RenderImage.css";

const renderIcon = (param, oStatus) => {
  switch (param) {
    case "video":
      return <div className={"bgImage videoIconDark " + oStatus} />;
    case "audio":
      return <div className={"bgImage audioIconDark " + oStatus} />;
    case "pdf":
      return <div className={"bgImage pdfIconDark " + oStatus} />;
    case "interactivity":
      return <div className={"bgImage interIconDark " + oStatus} />;
    case "scorm":
      return <div className={"bgImage scormIconDark " + oStatus} />;
    case "html":
      return <div className={"bgImage htmlIconDark " + oStatus} />;
    case "feedback":
      return <div className={"bgImage htmlIconDark " + oStatus} />;
    case "quiz":
      return <div className={"bgImage quizIconDark " + oStatus} />;
    case "youtube":
      return <YouTubeIcon style={{fill:oStatus === "grayBgColor" ? " #7c7f91" : "#275780",marginLeft:'7px',marginRight:'15px',fontSize:'25px'}}/>;
    case "vimeo":
      return <div className={"bgImage vimeoicon " + oStatus} />;
    default:
      return param;
  }
};
const RenderImage = ({ type, oStatus }) => {
  return (
    <>
      {oStatus === 0 ? (
        <>{renderIcon(type.toLowerCase (), "grayBgColor")}</>
      ) : (
        <>{renderIcon(type.toLowerCase (), "darkBgColor")}</>
      )}
    </>
  );
};

export default RenderImage;
