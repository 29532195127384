import React, { useState } from "react";
import {
  // Autocomplete,
  FormControl,
  TextField,
  Box,
  Stack,
  IconButton,
  Button,
  Backdrop,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { StyledTable } from "../LiveSession/LiveSession.Styled";
// import { useForm } from "../../../Hooks/useForm";
// import { CustomButton } from "../../../components/CustomButton/CustomButton";
import { Close } from "@mui/icons-material";
import { API } from "aws-amplify";
import config, { constants, Theme } from "../../config/aws-exports";
import { useDispatch, useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import validateRegistration, {
  validatePayLater,
  validatePayment,
} from "../LiveSession/RegisterForm/RegisterSessionValidate";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// import FormLabel from "@mui/material/FormLabel";
import { HeadingSecondary } from "../../components/Styles/Heading";
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
// import { regForm } from "../../../redux/RegFormStatus/RegFormStatus";
import { useEffect } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import moment from "moment";
const radioStyles = {
  color: Theme.color.primary,
  "&.Mui-checked": {
    color: Theme.color.primary,
  },
};

export const StyledButtons = {
  background: Theme.color.primary,
  color: Theme.color.white,
  borderColor: Theme.color.primary,
  textTransform: "unset",
  "&:hover": {
    background: Theme.color.primary,
    color: Theme.color.white,
    borderColor: Theme.color.primary,
  },
};
const getRazorPayment = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
  });
};

const STATUS = {
  DIRECT_TRANSFER: "directTransfer",
  PAY_LATER: "payLater",
  RAZOR_PAY: "razorPay",
};

const INITIAL_STATE = {
  regUsername: "",
  designation: "",
  department: "",
  organization: "",
  officeAddress: "",
  pin: "",
  mobile: "",
  state: "",
  city: "",
  email: "",
  std: "",
  gst: "",
  pan: "",
  ext: "",
};
export default function RegisterFrom({
  handleClose,
  sessionData: topicData,
  setRegButton,
  setSessionJoin,
  refetch,
  regData,
}) {
  console.log({ topicData });
  const userDetails = useSelector(authData);
  // const { values, handleChange, setValues } = useForm({
  //   regUsername: userDetails?.name,
  //   mobile: userDetails?.phone_number?.replace("+91", ""),
  //   email: userDetails?.email,
  //   regDetails: regData,
  // });
  const dispatch = useDispatch();
  const regStatus = useSelector((state) => state.regStatus.value);
  const [errors, setErrors] = useState({});
  const [regLoading, setRegLoading] = useState(false);
  const [values, setValues] = useState(INITIAL_STATE);
  const [payOption, setPayOption] = useState(false);
  const [directTransfer, setDirectTransfer] = useState(false);
  const [razorPay, setRazorPay] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [registrationForm, setRegistrationForm] = useState(true);
  const [payLater, setPayLater] = useState(false);
  const [currency, setCurrency] = useState("INR");
  const [currencyError, setCurrencyError] = useState("");
  const [billed, setBilled] = useState(false);
  // const [payOption, setPayOption] = useState(true);
  const [paymentLoader, setPaymentLoader] = useState(false);

  useEffect(() => {
    console.log(regData);
    if (
      regData !== undefined &&
      regData !== null &&
      Object.keys(regData).length > 0
    ) {
      regData.dt_ur_comment = "";
      regData.ur_comment = "";
      regData.payselect = "";
      setValues(regData);
    } else {
      setValues({
        ...INITIAL_STATE,
        regUsername: userDetails?.uData?.first_name,
        mobile: userDetails?.uData?.cno ? userDetails?.uData?.cno : "",
        email: userDetails?.uData?.emailid,
      });
    }
  }, []);

  const handleSubmit = async (id, status) => {

    if (payLater) {
      if (status === STATUS.PAY_LATER) {
        console.log(values);
        if (Object.keys(validatePayLater(values || {})).length !== 0) {
          setErrors(validatePayLater(values));
          setPayOption(true);
          return;
        }
      }
    }
    if (status === STATUS.DIRECT_TRANSFER) {
      if (Object.keys(validatePayment(values || {})).length !== 0) {
        setErrors(validatePayment(values));
        return;
      }
    }

    const bodyParam = {
      body: {
        ur_id: userDetails?.uData?.ur_id,
        tid: topicData?.vtid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    };
    console.log(bodyParam.body);
    if (status === STATUS.DIRECT_TRANSFER) {
      if (values.acknowledgementNumber) {
        bodyParam.body.pdetails = values.acknowledgementNumber;
      }
      if (values.dt_ur_comment) {
        bodyParam.body.dt_ur_comment = values.dt_ur_comment.replaceAll(
          "'",
          "''"
        );
      }
      bodyParam.body.pstatus = 0;
    }

    if (status === STATUS.PAY_LATER) {
      bodyParam.body.pstatus = 2;
      if (values.ur_comment) {
        bodyParam.body.ur_comment = values.ur_comment.replaceAll("'", "''");
      }
      if (values.payselect) {
        bodyParam.body.payselect = values.payselect.replaceAll("'", "''");
      }
    }

    try {
      setPaymentLoader(true);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/topicRegistration",
        bodyParam
      );
      // window.location.reload();
      setPaymentLoader(false);

      Swal.fire({
        title: "Success",
        text: response.body,
        icon: "success",
        confirmButtonColor: Theme.color.primary,
        confirmButtonText: "Ok",
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          refetch();
          handleClose();
        }
      });
    } catch (error) {
      setRegLoading(false);
    }
  };
  const handlePayChange = (e) => {
    const paymentMethod = e.target.value;
    setRazorPay(false);
    setDirectTransfer(false);
    setPayLater(false);
    setCurrencyError("");
    setPaymentStatus(paymentMethod);

    if (paymentMethod === STATUS.RAZOR_PAY) {
      setRazorPay(true);
    }

    if (paymentMethod === STATUS.DIRECT_TRANSFER) {
      setDirectTransfer(true);
    }
    if (paymentMethod === STATUS.PAY_LATER) {
      setPayLater(true);
    }
  };
  const handleCurrency = (e) => {
    const currency = e.target.value;
    setCurrency(currency);
  };

  const getRazorPayOrderId = async () => {
    let amount;
    const event = new Date();
    event.setHours(0);
    event.setMinutes(0);
    event.setSeconds(0);
    event.setMilliseconds(0);

    if (
      topicData?.earlypriceinr &&
      topicData?.earlypriceusd &&
      topicData?.earlydate &&
      // event.getTime() < parseInt(topicData?.earlydate)
      event.getTime() < new Date(topicData?.earlydate).getTime()
    ) {
      if (currency === "USD") {
        amount = topicData?.earlypriceusd * 100;
      } else {
        amount = topicData?.earlypriceinr * 100;
      }
    } else if (topicData?.perdis !== null && topicData?.perdis !== 0) {
      if (currency === "USD") {
        amount = topicData?.priceafterdisusd * 100;
      } else {
        amount = topicData?.priceafterdisinr * 100;
      }
    } else {
      if (currency === "USD") {
        amount = topicData?.priceusd * 100;
      } else {
        amount = topicData?.priceinr * 100;
      }
    }
    const bodyParam = {
      body: {
        ur_id: userDetails?.uData?.ur_id,
        tid: topicData?.vtid,
        price: amount,
        pstatus: 1,
        currency,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getRazorPayTopicOrderID",
        bodyParam
      );
      console.log({ response });
      if (
        response?.body?.orderId !== null &&
        response?.body?.orderId !== undefined
      ) {
        return response?.body?.orderId?.id;
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response?.body?.message,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: Theme.color.primary,
      }).then((result) => {
        if (result.isConfirmed) {
          handleClose();
          refetch();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
  const razorPaySuccessOrFailure = async (pstatus, id) => {
    const bodyParam = {
      body: {
        ur_id: userDetails?.uData?.ur_id,
        tid: topicData?.vtid,
        pstatus,
        schema: config.schema,
      },

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    };
    if (id) {
      bodyParam.body.pdetails = id;
    }

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/razorPayTopicSuccessOrFailure",
        bodyParam
      );
      refetch();
    } catch (err) {
      console.error(err);
    }
  };
  const handlePayment = async () => {
    if (paymentStatus === STATUS.RAZOR_PAY) {
      if (currency === "") {
        setCurrencyError("Choose payment type");
        setPayOption(true);
        return;
      }
    }
    setPaymentLoader(true);
    let amount;
    const event = new Date();
    event.setHours(0);
    event.setMinutes(0);
    event.setSeconds(0);
    event.setMilliseconds(0);
    console.log(event.getTime());
    console.log(new Date(topicData?.earlydate).getTime());
    if (
      topicData?.earlypriceinr &&
      topicData?.earlypriceusd &&
      topicData?.earlydate &&
      event.getTime() < new Date(topicData?.earlydate).getTime()
    ) {
      if (currency === "USD") {
        amount = topicData?.earlypriceusd * 100;
        console.log(amount);
      } else {
        amount = topicData?.earlypriceinr * 100;
        console.log(amount);
      }
    } else if (topicData?.perdis !== null && topicData?.perdis !== 0) {
      if (currency === "USD") {
        amount = topicData?.priceafterusd * 100;
      } else {
        amount = topicData?.priceafterinr * 100;
      }
    } else {
      if (currency === "USD") {
        amount = topicData?.priceusd * 100;
      } else {
        amount = topicData?.priceinr * 100;
      }
    }

    const response = await getRazorPayment(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!response) {
      console.log("error");
      return;
    }
    let order_id = await getRazorPayOrderId();
    if (order_id !== undefined) {
      var options = {
        key: constants.razorPayDev, // Enter the Key ID generated from the Dashboard
        amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        name: "USP",
        description: "live Transaction",
        currency,
        image: `https://${config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/USP logo.PNG`,

        handler: function (response) {
          console.log(response);
          if (response) {
            razorPaySuccessOrFailure(true, response.razorpay_payment_id);
            refetch();
          }
        },
        prefill: {
          name: userDetails?.name,
          email: userDetails?.email,
        },
        notes: {
          address: "Enahnzed Education pvt. ltd.",
        },
        order_id,
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: () => {
            console.log("hello");
            razorPaySuccessOrFailure(false, undefined);
          },
        },
      };
      if (regData?.mobile) {
        options.prefill.contact = regData?.mobile;
      }
      // options.order_id = await getRazorPayOrderId();
      setPaymentLoader(false);
      console.log(JSON.stringify(options, null, 2));
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        if (response) {
          razorPaySuccessOrFailure(false, undefined);
          refetch();
        }
      });
      rzp1.open();
      handleClose();
      refetch();
    }
  };

  const handleBillingSubmit = async (event) => {
    event.preventDefault();

    if (Object.keys(validateRegistration(values)).length !== 0) {
      setErrors(validateRegistration(values));
      return;
    } else {
      setBilled(true);
      const bodyParam = {
        body: {
          ur_id: userDetails?.uData?.ur_id,
          billAdd: values,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwttoken,
        },
      };

      try {
        await API.post(
          config.aws_cloud_logic_custom_name,
          "/billAddToProfile",
          bodyParam
        );
        
        if (topicData?.coursetype === 'paid') {
          setRegistrationForm(false);
          setPayOption(true);
          setBilled(false);
        } else {
          handleSubmit(undefined, STATUS.PAY_LATER);
        }
      } catch (error) {
        setBilled(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (JSON.stringify(values) === JSON.stringify(regData)) {
    }
    if (paymentStatus === STATUS.PAY_LATER) {
      setValues((prevState) => ({
        ...prevState, "ur_comment": value,
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        [name]: ["acknowledgementNumber", "dt_ur_comment"].includes(name)
          ? value.trim()
          : value,
      }));
    }
    // setValues(prevState => {return{ ...prevState, [name]: value?.trimStart()?.split("  ").join(" ") }});
  };
  const handleSelectChange = (e) => {
    const { value } = e.target;

    if (JSON.stringify(values) === JSON.stringify(regData)) {
    }
    if (paymentStatus === STATUS.PAY_LATER) {
      setValues((prevState) => ({
        ...prevState, "payselect": value,
      }));
    }
  };
  return (
    <>
      <DialogTitle id="responsive-dialog-title" sx={{ position: "relative" }}>
        {/* {"Please provide your details"} */}
        {regStatus.status ? "Please provide your details" : "Payment options"}
        <IconButton
          onClick={() => {
            handleClose();
          }}
          sx={{ position: "absolute", right: 10, top: 10 }}
        >
          <Close sx={{ fontSize: "2rem" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          {payOption && (
            <>
              <FormControl component="fieldset">
                <Box sx={{ color: "#99999" }}>Choose a payment option</Box>
                <RadioGroup
                  aria-label="gender"
                  defaultValue=""
                  name="radio-buttons-group"
                  onChange={handlePayChange}
                >
                  <FormControlLabel
                    value={STATUS.PAY_LATER}
                    control={<Radio sx={radioStyles} />}
                    label="Pay Later"
                    sx={{ fontWeight: 900, color: "#000" }}
                  />
                  <FormControlLabel
                    value={STATUS.DIRECT_TRANSFER}
                    control={<Radio sx={radioStyles} />}
                    label="Direct transfer to the bank account of USP"
                    sx={{ fontWeight: 900, color: "#000" }}
                  />

                  <FormControlLabel
                    value={STATUS.RAZOR_PAY}
                    control={<Radio sx={radioStyles} />}
                    label="Payment through Razor Pay (Credit card, Debit card, UPI)"
                    sx={{ fontWeight: 900, color: "#000" }}
                  />
                  {paymentStatus === STATUS.RAZOR_PAY && (
                    <>
                      <RadioGroup
                        row
                        aria-label="currency"
                        defaultValue=""
                        value={currency}
                        name="radio-buttons-group"
                        onChange={handleCurrency}
                      >
                        <FormControlLabel
                          value="INR"
                          control={<Radio sx={radioStyles} />}
                          label="INR"
                          sx={{ fontWeight: 900, color: "#000", ml: 2.5 }}
                        />
                        {topicData?.priceusd !== null && (
                          <FormControlLabel
                            value="USD"
                            control={<Radio sx={radioStyles} />}
                            label="USD"
                            sx={{ fontWeight: 900, color: "#000" }}
                          />
                        )}
                      </RadioGroup>
                      {currencyError !== "" && (
                        <FormHelperText sx={{ color: "red" }}>
                          {currencyError}
                        </FormHelperText>
                      )}
                    </>
                  )}
                  {topicData.paylater && (
                    <FormControlLabel
                      value={STATUS.PAY_LATER}
                      control={<Radio sx={radioStyles} />}
                      label={`Pay later - ( pay within : ${moment(
                        parseInt(topicData?.pay_within)
                      ).format("L")} )`}
                      sx={{ fontWeight: 900, color: "#000" }}
                    />
                  )}
                  {paymentStatus === STATUS.PAY_LATER && (
                    <div style={{display:'contents'}}>
                      <div style={{marginBottom:'10px'}}>
                      <p>Select an option</p>
                      <Select
                        labelId="my-select-label"
                        name="mySelect"
                        value={values.payselect}
                        label="Select an option"
                        error={errors.payselect ? true : false}
                        helperText={errors.payselect || ""}
                        style={{width:'100%'}}
                        onChange={handleSelectChange}
                        defaultValue={-1}
                      >
                        <MenuItem value={-1} disabled>Select an option</MenuItem>
                        <MenuItem value="Regulators free">Regulators free</MenuItem>
                        <MenuItem value="Trade organization">Trade organization</MenuItem>
                        <MenuItem value="Business contact">Business contact</MenuItem>
                      </Select>
                      {errors.payselect && <FormHelperText style={{color:'red'}}>{errors.payselect}</FormHelperText>}
                      </div>
                      <TextField
                        type="text"
                        multiline
                        placeholder="Comments"
                        error={errors.ur_comment ? true : false}
                        helperText={errors.ur_comment}
                        defaultValue={values.ur_comment || ""}
                        row={4}
                        maxCols={8}
                        onChange={handleChange}
                      />
                      
                    </div>
                  )}

                </RadioGroup>
              </FormControl>

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 3 }}
              >
                <Button
                  sx={StyledButtons}
                  onClick={() => {
                    if (paymentStatus === "") {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Select any one payment option and then proceed.",
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonColor: Theme.color.primary,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setPayOption(true);
                        }
                      });
                    } else {
                      setPayOption(false);
                      if (razorPay) {
                        handlePayment();
                      }
                      if (payLater) {
                        handleSubmit(undefined, STATUS.PAY_LATER);
                      }
                    }
                  }}
                  size="small"
                >
                  Next
                </Button>
              </Stack>
            </>
          )}
          {!payOption && directTransfer && (
            <>
              <HeadingSecondary>
                Direct transfer to the following bank account of USP
              </HeadingSecondary>
              <p style={{ margin: "1.2rem 0 0 0" }}>
                Beneficiary Name: United States Pharmacopeia India Pvt. Ltd.
              </p>
              <p>Account No: 000805005833</p>
              <p> IFSC code : ICIC0000008</p>
              <p>
                Branch Address : ICICI Bank, 6-2-1012 TGV Mansion, Khairatabad
                branch, Hyderabad - 500 004 Telangana.
              </p>
              <p>MICR Number / Bank Key / Bank Code : 500229002.</p>
              <p>Bank Account Type: Current Account.</p>

              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ mt: 2 }}
              >
                <HeadingSecondary>
                  Please provide the NEFT/payment acknowledgement number :
                </HeadingSecondary>
                <FormControl sx={{ minWidth: 300 }}>
                  <TextField
                    fullWidth
                    size="small"
                    error={errors.acknowledgementNumber ? true : false}
                    helperText={errors.acknowledgementNumber}
                    color="info"
                    value={values.acknowledgementNumber || ""}
                    name="acknowledgementNumber"
                    onChange={handleChange}
                  />
                </FormControl>
              </Stack>

              <TextField
                type="text"
                multiline
                placeholder="Comments"
                error={errors.dt_ur_comment ? true : false}
                helperText={errors.dt_ur_comment}
                name="dt_ur_comment"
                value={values.dt_ur_comment || ""}
                rows={4}
                sx={{ width: "100%", mt: 2 }}
                onChange={handleChange}
              />

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 3 }}
              >
                <LoadingButton
                  loading={regLoading ? true : false}
                  disabled={regLoading ? true : false}
                  variant="contained"
                  sx={StyledButtons}
                  onClick={() => {
                    if (directTransfer) {
                      handleSubmit(undefined, STATUS.DIRECT_TRANSFER);
                    }
                  }}
                >
                  Next
                </LoadingButton>
              </Stack>
            </>
          )}
        </Box>
        {!payOption && !directTransfer && (
          <>
            <DialogContentText>
              Company name and address you provide here will be printed on your
              taxable invoice (not editable after invoice is generated). Write
              in BLOCK letters only.Name you provide here will be printed on
              your participation certificate.
            </DialogContentText>
            <Box
              component="form"
              onSubmit={handleBillingSubmit}
              autoComplete="off"
            >
              <StyledTable>
                <tr>
                  <td>
                    Name <span>*</span>
                  </td>
                  <td>
                    <FormControl sx={{ minWidth: 300 }}>
                      <TextField
                        fullWidth
                        size="small"
                        error={errors.regUsername ? true : false}
                        helperText={errors.regUsername}
                        color="info"
                        value={values.regUsername || ""}
                        name="regUsername"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td>
                    Designation <span>*</span>
                  </td>
                  <td>
                    <FormControl sx={{ minWidth: 300 }}>
                      <TextField
                        fullWidth
                        size="small"
                        error={errors.designation ? true : false}
                        helperText={errors.designation}
                        color="info"
                        value={values.designation || ""}
                        name="designation"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td>Department</td>
                  <td>
                    <FormControl sx={{ minWidth: 300 }}>
                      <TextField
                        fullWidth
                        size="small"
                        error={errors.department ? true : false}
                        helperText={errors.department}
                        color="info"
                        value={values.department || ""}
                        name="department"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td>
                    Organization<span>*</span>
                  </td>
                  <td>
                    <FormControl sx={{ minWidth: 300 }}>
                      <TextField
                        fullWidth
                        size="small"
                        error={errors.organization ? true : false}
                        helperText={errors.organization}
                        color="info"
                        value={values.organization || ""}
                        name="organization"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td>
                    Office Address <span>*</span> <br /> (for invoice)
                  </td>
                  <td>
                    <Box
                      fullWidth
                      component="textarea"
                      sx={{ resize: "none", width: { xs: "100%" }, p: 1 }}
                      value={values.officeAddress || ""}
                      name="officeAddress"
                      onChange={handleChange}
                    ></Box>
                    {
                      <p
                        style={{
                          color: "#D32f2f",
                          fontSize: "14px",
                          fontFamily: "Roboto ",
                          marginTop: ".5rem",
                        }}
                      >
                        {errors.officeAddress}
                      </p>
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    City<span>*</span>
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <FormControl sx={{ minWidth: 300 }}>
                        <TextField
                          fullWidth
                          size="small"
                          error={errors.city ? true : false}
                          helperText={errors.city}
                          color="info"
                          value={values.city || ""}
                          name="city"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <Box>
                        PIN<span style={{ color: "red" }}>*</span>
                      </Box>
                      <FormControl sx={{ width: 150 }}>
                        <TextField
                          fullWidth
                          size="small"
                          error={errors.pin ? true : false}
                          helperText={errors.pin}
                          color="info"
                          value={values.pin || ""}
                          name="pin"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <Box>
                        State<span style={{ color: "red" }}>*</span>
                      </Box>
                      <FormControl sx={{ minWidth: 300 }}>
                        <TextField
                          fullWidth
                          size="small"
                          error={errors.state ? true : false}
                          helperText={errors.state}
                          color="info"
                          value={values.state || ""}
                          name="state"
                          onChange={handleChange}
                        />
                      </FormControl>
                      {/* <Autocomplete
                        disablePortal
                        size="small"
                        id="combo-box-demo"
                        options={top100Films}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} />}
                      /> */}
                    </Stack>
                  </td>
                </tr>
                <tr>
                  <td>
                    Email<span>*</span>
                  </td>
                  <td>
                    <FormControl sx={{ minWidth: 300 }}>
                      <TextField
                        fullWidth
                        size="small"
                        error={errors.email ? true : false}
                        helperText={errors.email}
                        color="info"
                        value={userDetails.email || ""}
                        name="email"
                        onChange={handleChange}
                        inputProps={{ readOnly: true }}
                      />
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td>STD</td>
                  <td>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <FormControl sx={{ width: 100 }}>
                        <TextField
                          fullWidth
                          size="small"
                          error={errors.std ? true : false}
                          helperText={errors.std}
                          color="info"
                          value={values.std || ""}
                          name="std"
                          onChange={handleChange}
                        />
                      </FormControl>
                      <Box>
                        Phone
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </Box>
                      <FormControl sx={{ minWidth: 200 }}>
                        <TextField
                          fullWidth
                          size="small"
                          error={errors.phone ? true : false}
                          helperText={errors.phone}
                          color="info"
                          value={values.phone || ""}
                          inputProps={{ maxLength: 10 }}
                          name="phone"
                          onChange={handleChange}
                        />
                      </FormControl>
                      {/* <Box>EXT</Box>
                      <FormControl sx={{ width: 80 }}>
                        <TextField
                          fullWidth
                          size="small"
                          error={errors.ext ? true : false}
                          helperText={errors.ext}
                          color="info"
                          value={values.ext || ""}
                          name="ext"
                          onChange={handleChange}
                        />
                      </FormControl> */}
                      <Box>
                        Mobile <span style={{ color: "red" }}>*</span>
                      </Box>

                      <FormControl sx={{ minWidth: 250 }}>
                        <TextField
                          fullWidth
                          size="small"
                          inputProps={{ maxLength: 15 }}
                          error={errors.mobile ? true : false}
                          helperText={errors.mobile}
                          color="info"
                          value={values.mobile || ""}
                          name="mobile"
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Stack>
                  </td>
                </tr>
                <tr>
                  <td>
                    GST Number <br /> (if applicable)
                  </td>
                  <td>
                    <FormControl sx={{ minWidth: 300 }}>
                      <TextField
                        fullWidth
                        size="small"
                        error={errors.gst ? true : false}
                        helperText={errors.gst}
                        color="info"
                        value={values.gst || ""}
                        name="gst"
                        onChange={handleChange}
                      />
                    </FormControl>
                  </td>
                </tr>
              </StyledTable>
              <Box>
                If you registering first time, please provide your Company's:
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ mt: 2 }}
                spacing={2}
              >
                <Box sx={{ paddingLeft: 11 }}>PAN</Box>
                <FormControl sx={{ minWidth: 300 }}>
                  <TextField
                    fullWidth
                    size="small"
                    error={errors.pan ? true : false}
                    helperText={errors.pan}
                    color="info"
                    value={values.pan || ""}
                    name="pan"
                    onChange={handleChange}
                  />
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                {Object.keys(regData).length === 0 && (
                  <LoadingButton
                    loading={billed ? true : false}
                    disabled={billed ? true : false}
                    type="submit"
                    sx={StyledButtons}
                  >
                    Save & register
                  </LoadingButton>
                )}
                {Object.keys(regData).length > 0 &&
                  JSON.stringify(values) === JSON.stringify(regData) && (
                    <Button
                      sx={StyledButtons}
                      onClick={() => {
                        if (topicData?.coursetype === "paid") {
                          setPayOption(true);
                          setCurrency("");
                          setCurrencyError("");

                        } else {
                          handleSubmit(undefined, STATUS.PAY_LATER);
                        }
                      }}
                    >
                      Next
                    </Button>
                  )}
                {Object.keys(regData).length > 0 &&
                  JSON.stringify(values) !== JSON.stringify(regData) && (
                    <LoadingButton
                      loading={billed ? true : false}
                      disabled={billed ? true : false}
                      type="submit"
                      sx={StyledButtons}
                    >
                      Save & register
                    </LoadingButton>
                  )}
              </Stack>
            </Box>
          </>
        )}
      </DialogContent>
      <Backdrop open={paymentLoader} sx={{ zIndex: "theme.drawer" + 1 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}
