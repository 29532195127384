import React, { useRef, useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Theme } from "../../config/aws-exports";
import { Box } from "@mui/system";

import {
  HeadingPrimary,
  HeadingSecondary,
  HeadingThree,
} from "../../components/Styles/Heading";
import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CustomButton } from "../../components/CustomButton/CustomButton";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";

import { useTheme } from "@mui/material/styles";

import { useSelector } from "react-redux";
import RegisterFrom, { StyledButtons } from "./RegisterForm/RegisterFrom";
import FeedbackForm from "./FeedbackForm/FeedbackForm";
import moment from "moment";
import SignInAndSignUp from "../../components/SignInAndSignUp/SignInAndSignUp";
import { useQuery } from "react-query";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { authData } from "../../redux/auth/authSlice";
import LoadingButton from "@mui/lab/LoadingButton";

import SpeakerDialog from "./SpeakerDialog";
import CertificatePopUp from "./CertificatePopUp";
import SlidesPopUp from "./SlidesPopUp";
import Swal from "sweetalert2";
import "./liveSession.css";

import { useQueryParams } from "../../Hooks/useQueryParams";
import { Wrapper } from "./LiveSession.Styled";
import DialogContent from "@mui/material/DialogContent";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../../assets/USP logo R.png";
import { makeStyles } from "@mui/styles";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import USPFeedback from "./FeedbackForm/USPFeedback";
const INITIAL_STATE = {
  open: false,
  buttonState: {
    join: false,
    expInterest: false,
    registered: false,
    sessionCompleted: false,
    clickState: {
      registerButton: false,
      feedbackButton: false,
    },
  },
};
const StyledButtonJoin = {
  background: "#6a5acd",
  color: Theme.color.white,
  borderColor: "#6a5acd",
  textTransform: "unset",
  my: 1,
  "&:hover": {
    background: "#6a5acd",
    color: Theme.color.white,
    borderColor: "#6a5acd",
  },
};
const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2rem",
  },
  header: {
    background: "#DED7CF",
    padding: "0.5rem 2rem",
    display: "flex",
    height: "3rem",
    justifyContent: "space-between",
    "& > h2": {
      margin: 0,
      color: "#E96138",
    },
    "& > div": {
      position: "relative",
      right: "-3rem",
      top: "0",
    },
  },
  body: {
    margin: "2rem 6rem",
    "& > h4": {
      textAlign: "center",
      textDecoration: "underline",
    },
  },
  table: {
    border: "3px solid",
    borderCollapse: "collapse",
    width: "100%",
    margin: "1rem 0rem",
    "& > tr > td": {
      border: "2px solid",
      padding: "0px 6px",
    },
    "& > tr > th": {
      border: "2px solid",
      textAlign: "left",
      padding: "0px 6px",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5rem 2rem 2rem",
    borderTop: "3px solid #B26253",
    "& > h3": {
      margin: 0,
    },
    fontSize: "18px",
  },
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
}));
const LiveSession = () => {
  const classes = useStyles();
  const query = useQueryParams();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  var re = /(?:\.([^.]+))?$/;
  const userDetails = useSelector(authData);
  const [expressInterest, setExpressInterest] = useState(false);

  const [loginOpen, setLoginOpen] = useState(false);
  const onlySignUp = useRef(false);
  const sessionData = useSelector((state) => state.sessionData.value);

  const [openSpeaker, setSpeaker] = useState(false);
  const [speakerDetails, setSpeakerDetails] = useState({});
  const [sessionCompleted, setSessionCompleted] = useState(false);
  const [expressInterestLoader, setExpressInterestLoader] = useState(false);
  const [regOpen, setRegOpen] = useState(false);
  const [regButton, setRegButton] = useState(false);
  const [sessionJoin, setSessionJoin] = useState(false);
  const [gray, setGray] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [slides, setSlides] = useState(false);
  const [slideOpen, setSlideOpen] = useState(false);
  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const [certificateOpen, setCertificateOpen] = useState(false);

  const [checkSessionLimitLoader, setCheckSessionLimitLoader] = useState(false);
  const [joinButtonLoader, setJoinButtonLoader] = useState(false);
  const [paylater, setPaylater] = useState(false);
  const [fdata, setFData] = useState({});

  const [invoiceOpenNew, setInvoiceOpenNew] = useState(false);
  const [viewingState, setViewingState] = useState(false);
  const [invoiceOpen, setInvoiceOpen] = useState(false);

  const handleClickCertificateOpen = () => {
    setCertificateOpen(true);
  };
  const feedbackFormClose = () => {
    setFeedbackFormOpen(false);
  };
  const handleClickSlidesOpen = () => {
    setSlideOpen(true);
  };

  const handleClickSpeakerOpen = async (details) => {
    setSpeaker(true);
    setSpeakerDetails(details);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { data, isLoading, refetch } = useQuery(
    ["sessionDetails", query.get("sid")],
    () =>
      API.post(config.aws_cloud_logic_custom_name, "/getSessionDetails", {
        body: {
          ur_id: userDetails?.uData?.ur_id,
          tenant: userDetails?.uData?.oid,
          eid: userDetails.username,
          sid: query.get("sid"),
          schema: config.schema,
          emailid: userDetails?.email,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((res) => {
        return res;
      })
  );

  useEffect(() => {
    if (data) {
      const multidayarray = data?.sData?.date_list;
      if (multidayarray !== undefined && multidayarray != null) {
        var startTime = moment(
          new Date(multidayarray[0]?.combineStartTime).getTime()
        );
        var endTime = moment(
          new Date(
            multidayarray[multidayarray.length - 1]?.combineEndTime
          ).getTime()
        );
        var minutesDiff = endTime.diff(startTime, "minutes");
        let currentTime = data.currentTime;
        let sTime = new Date(multidayarray[0].combineStartTime).getTime();
        let sessionData = data?.sData;
        let userData = data?.uData;
        let expInterest = data?.ei;

        let timeDuration = moment(sTime).add(minutesDiff, "m").toDate();
        let JoinTime = moment(currentTime).toDate();
        let joinButton = false;

        for (let item of multidayarray) {
          if (item.date === new Date(currentTime).toISOString().slice(0, 10)) {
            let before10Min = moment(item.combineStartTime)
              .subtract(10, "m")
              .toDate();

            if (before10Min.getTime() <= JoinTime.getTime()) {
              joinButton = true;
            }
          }
        }
        if (currentTime < timeDuration.getTime()) {
          getInvoice();
          if (
            userData !== undefined &&
            Object.keys(userData).length > 0 &&
            userData?.u_type !== 0
          ) {
            if (userData.u_type === 2) {
              setRegButton(false);
              setSessionJoin(false);
            } else if (userData.pstatus === 10) {
              setRegButton(true);
              setPaylater(false);
            } else if (userData.pstatus === 5) {
              setRegButton(false);
              setInvoiceOpenNew(true);
              setPaylater(false);
            } else if (userData.pstatus === 2 && sessionData.paid === "true") {
              setRegButton(false);
              setPaylater(true);
            } else if (
              userData.pstatus === undefined ||
              userData.pstatus === null
            ) {
              setRegButton(false);
              setInvoiceOpenNew(true);
              setPaylater(true);
            } else {
              setExpressInterest(false);
              setRegButton(false);
              setPaylater(false);
              setInvoiceOpenNew(false);
              if (
                userData !== undefined &&
                Object.keys(userData).length > 0 &&
                (userData?.aprstatus === 1 ||
                  sessionData?.paid === "false" ||
                  sessionData?.paid === false)
              ) {
                if (joinButton && sessionData?.weblink !== null) {
                  setSessionJoin(true);
                  setGray(false);
                } else {
                  setSessionJoin(true);
                  setGray(true);
                }
              } else {
                setSessionJoin(true);
                setGray(true);
              }
            }
          } else {
            setExpressInterest(true);
            setRegButton(false);
            if (moment(currentTime).isAfter(moment(startTime))) {
              setSessionJoin(false);
              if (
                sessionData?.reg_start == null ||
                sessionData?.reg_start === true
              ) {
                setRegButton(true);
                setExpressInterest(false);
              }
            } else if (sessionData?.reg_start === true) {
              setExpressInterest(false);
              setSessionJoin(false);
              setRegButton(true);
            } else {
              if (expInterest !== undefined && expInterest === true) {
                setExpressInterest(false);
              }
            }
          }
        } else if (
          userData !== undefined &&
          Object.keys(userData).length > 0 &&
          userData?.aprstatus === 1
        ) {
          setSessionCompleted(true);
          setRegButton(false);
          setExpressInterest(false);
          setSessionJoin(false);

          setSlides(false);
          if (
            userData?.attend !== undefined &&
            userData?.attend !== null &&
            userData?.attend &&
            (query.get("feedback") || data?.feedback)
          ) {
            setCertificate(true);
          } else {
            setCertificate(false);
          }
          if (
            sessionData?.slides !== null &&
            sessionData?.slides?.length > 0 &&
            (query.get("feedback") || data?.feedback)
          ) {
            setSlides(true);
          } else {
            setSlides(false);
          }
          setFeedback(true);

          if (query.get("feedback") || data?.feedback) {
            setFeedback(false);
          } else {
            setFeedback(true);
          }
        } else {
          // time not and not registered
          if (sessionData?.reg_start == null || sessionData?.reg_start === true)
            setRegButton(true);
          setFeedback(false);

          if (
            data?.sData?.date_list[0]?.combineStartTime !== undefined ||
            (userData?.aprstatus === null && data.ei)
          ) {
            setExpressInterest(false);
          } else {
            setExpressInterest(true);
          }

          setSessionJoin(false);
          setSlides(false);
          setCertificate(false);
        }
      } else {
        let startTime = moment(parseInt(data?.sData?.startdate));
        let endTime = moment(parseInt(data?.sData?.enddate));
        let minutesDiff = endTime.diff(startTime, "minutes");
        let currentTime = data.currentTime;
        let sTime = parseInt(data?.sData?.startdate);
        let sessionData = data?.sData;
        let userData = data?.uData;
        let expInterest = data?.ei;
        let registrationStartDate = parseInt(data?.sData?.regdate);

        let timeDuration = moment(sTime).add(minutesDiff, "m").toDate();
        let before10Min = moment(sTime).subtract(10, "m").toDate();
        let JoinTime = moment(currentTime).toDate();

        if (currentTime < timeDuration.getTime()) {
          if (userData !== undefined && Object.keys(userData).length > 0) {
            if (userData.u_type === 2) {
              setRegButton(false);
              setSessionJoin(false);
            } else if (userData.pstatus === 10) {
              setRegButton(true);
              setPaylater(false);
            } else if (userData.pstatus === 5) {
              setRegButton(false);
              setPaylater(false);
            } else if (userData.pstatus === 2 && sessionData.paid === "true") {
              setRegButton(false);
              setPaylater(true);
            } else if (
              userData.pstatus === undefined ||
              userData.pstatus === null
            ) {
              setRegButton(false);
              setInvoiceOpenNew(true);
              setPaylater(false);
            } else {
              setExpressInterest(false);
              setRegButton(false);
              if (
                userData !== undefined &&
                Object.keys(userData).length > 0 &&
                (userData?.aprstatus === 1 ||
                  sessionData?.paid === "false" ||
                  sessionData?.paid === false)
              ) {
                if (
                  before10Min.getTime() <= JoinTime.getTime() &&
                  JoinTime.getTime() <= timeDuration.getTime() &&
                  sessionData?.weblink !== null
                ) {
                  setSessionJoin(true);
                  setGray(false);
                } else {
                  setSessionJoin(true);
                  setGray(true);
                }
              } else {
                setSessionJoin(true);
                setGray(true);
              }
            }
          } else {
            setExpressInterest(true);

            if (moment(currentTime).isAfter(moment(startTime))) {
              setRegButton(false);
              setExpressInterest(false);
              setSessionJoin(false);
            } else if (sessionData?.reg_start) {
              setExpressInterest(false);
              setSessionJoin(false);
              setRegButton(true);
            } else {
              if (
                registrationStartDate <= JoinTime.getTime() &&
                JoinTime.getTime() < sTime
              ) {
                setRegButton(true);
                setExpressInterest(false);
              } else {
                setRegButton(false);
                if (expInterest !== undefined && expInterest === true) {
                  setExpressInterest(false);
                }
              }
            }
          }
        } else if (
          userData !== undefined &&
          Object.keys(userData).length > 0 &&
          userData?.aprstatus === 1
        ) {
          // timeout and registered
          setSessionCompleted(true);
          setRegButton(false);
          setExpressInterest(false);
          setSessionJoin(false);
          setSlides(false);
          if (
            userData?.attend !== undefined &&
            userData?.attend !== null &&
            userData?.attend !== 0 &&
            (query.get("feedback") || data?.feedback)
          ) {
            setCertificate(true);
          } else {
            setCertificate(false);
          }
          if (
            sessionData?.slides !== null &&
            sessionData?.slides?.length > 0 &&
            (query.get("feedback") || data?.feedback)
          ) {
            setSlides(true);
          } else {
            setSlides(false);
          }
          if (query.get("feedback") || data?.feedback) {
            setFeedback(false);
          } else {
            setFeedback(true);
          }
        } else {
          // time not and not registered
          setFeedback(false);
          setRegButton(false);

          setExpressInterest(false);

          setSessionJoin(false);
          setSlides(false);
          setCertificate(false);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const feedback = query.get("feedback");
    const sid = query.get("sid");
    const userId = query.get("userId");
    if (sid && userId && feedback) {
      const submitFeedback = async () => {
        try {
          API.post(
            config.aws_cloud_logic_custom_name,
            `/submit-feedback?user_id=${userId}&session_id=${sid}&feedback=${feedback}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      };

      submitFeedback();
    }

    return () => {};
  }, []);

  const handleClose = () => {
    setRegOpen(false);
    setRegButton(true);
    refetch();
  };
  const handleExpressInterest = async () => {
    setExpressInterestLoader(true);
    try {
      setExpressInterestLoader(true);
      await API.post(config.aws_cloud_logic_custom_name, "/expressInterest", {
        body: {
          sid: query.get("sid"),
          ur_id: userDetails?.uData?.ur_id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setExpressInterestLoader(false);
      setExpressInterest(false);
      Swal.fire({
        title: "Success",
        text: "You will be notified when the registration starts",
        icon: "success",
        confirmButtonColor: Theme.color.primary,
        confirmButtonText: "Ok",
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          refetch();
        }
      });
    } catch (error) {
      console.error(error);
      setExpressInterestLoader(false);
    }
  };

  function formatUrl(url) {
    var httpString = "http://",
      httpsString = "https://";
    if (
      url.substr(0, httpString.length) !== httpString &&
      url.substr(0, httpsString.length) !== httpsString
    )
      url = httpString + url;

    return url;
  }
  const handleJoinSession = async () => {
    try {
      setJoinButtonLoader(true);
      await API.post(config.aws_cloud_logic_custom_name, "/joinSession", {
        body: {
          oid: "USP",
          tenant: userDetails.tenant,
          eid: userDetails.sub,
          sessionID: query.get("sid"),
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setJoinButtonLoader(false);
      const url = formatUrl(data?.sData?.weblink);
      window.open(url, "_blank");
    } catch (error) {
      console.error(error);
      setJoinButtonLoader(false);
    }
  };
  const checkForSlots = async () => {
    setCheckSessionLimitLoader(true);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/checkSessionSlots",
        {
          body: {
            ur_id: userDetails?.uData?.ur_id,
            pstatus: 0,
            assign: "self",
            sid: query.get("sid"),
            schema: config.schema,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setCheckSessionLimitLoader(false);

      if (response.slots === false) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: Theme.color.primary,
        }).then((result) => {
          if (result.isConfirmed) {
            handleClose();
            refetch();
          }
        });
      } else {
        setRegOpen(true);
      }
    } catch (err) {
      setCheckSessionLimitLoader(false);
      console.error(err);
    }
  };
  const breadcrumbs = [
    <Link to={sessionData.path !== "wishlist" ? "/dashboard" : "/wishlist"}>
      {sessionData.path !== "wishlist" ? "Dashboard" : "Wishlist"}
    </Link>,
    <Typography key="3" color={Theme.color.text}>
      {data?.sData?.sn}
    </Typography>,
  ];
  const getInvoice = async () => {
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getInvoices",
        {
          body: {
            sid: query.get("sid"),
            ur_id: userDetails?.uData?.ur_id,
            schema: config.schema,
            action: "get",
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.invoices?.newInv.length > 0) {
        setViewingState(response.invoices?.newInv[0].invoice_dtls);
      }
    } catch (error) {
      console.error(error);
    }
  };
  async function downloadcode() {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#invoicepdf"), {
      allowTaint: true,
      useCORS: true,
      logging: true,
    });
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(
      viewingState.sn?.length >= 90
        ? viewingState.sn?.substring(0, 90)
        : viewingState.sn + ".pdf"
    );
  }
  function numberToWordsOnly(number) {
    const ones = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const scales = ["", "thousand", "million", "billion", "trillion"];

    if (number === 0) return "zero only";

    const sign = number < 0 ? "negative " : "";
    let absoluteNumber = Math.abs(number);

    let scaleCount = 0;
    let words = "";

    while (absoluteNumber > 0) {
      const hundred = absoluteNumber % 1000;
      if (hundred > 0) {
        let hundredWords = "";
        if (hundred < 10) hundredWords = ones[hundred];
        else if (hundred < 20) hundredWords = ones[hundred % 10] + "teen";
        else if (hundred < 100)
          hundredWords =
            tens[Math.floor(hundred / 10)] + " " + ones[hundred % 10];
        else
          hundredWords =
            ones[Math.floor(hundred / 100)] +
            " hundred " +
            tens[Math.floor((hundred % 100) / 10)] +
            " " +
            ones[hundred % 10];
        words = hundredWords + " " + scales[scaleCount] + " " + words;
      }
      absoluteNumber = Math.floor(absoluteNumber / 1000);
      scaleCount++;
    }

    return sign + words.trim() + " only";
  }

  function calculateDiscountedPrice(price, gst, discount) {
    // Convert values to numbers
    const numericPrice = +price;
    const numericGst = +gst;
    const numericDiscount = +discount;

    // Check if the values are valid numbers
    if (isNaN(numericPrice) || isNaN(numericGst) || isNaN(numericDiscount)) {
      console.error("Invalid input. Please provide valid numeric values.");
      return null;
    }

    // Calculate discounted price
    const discountedPrice = numericPrice * (1 - numericDiscount / 100);

    // Step 2: Calculate GST amount
    const gstAmount = (discountedPrice * numericGst) / 100;

    // Step 3: Calculate total amount
    var totalAmount = discountedPrice + gstAmount;
    totalAmount = Math.round(totalAmount);
    return totalAmount;
  }
  return (
    <Wrapper>
      {data !== undefined &&
        data?.sData !== undefined &&
        Object.keys(data?.sData).length > 0 && (
          <>
            <Stack
              spacing={2}
              sx={{ margin: { xs: 1, sm: 3 }, paddingBottom: 3 }}
            >
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ color: Theme.color.black }}
              >
                {breadcrumbs}
              </Breadcrumbs>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <HeadingPrimary>{data?.sData?.sn}</HeadingPrimary>
                <Box>
                  {true && (
                    <CustomButton
                      onClick={() => {
                        setFeedbackFormOpen(true);
                        let type = "";

                        if (data?.sData?.stype == 1) {
                          type = "online";
                        } else if (data?.sData?.stype == 2) {
                          type = "classroom";
                        } else {
                          type = "hybrid";
                        }

                        setFData({
                          ur_id: userDetails?.uData?.ur_id,
                          type: type,
                          sid: query.get("sid"),
                        });

                        // window.open(
                        //   `https://usp.az1.qualtrics.com/jfe/form/SV_2ldw3GxmBPdx4uq?ProgramName=${encodeURIComponent(
                        //     data?.sData?.sn
                        //   )}&EmailAddress=${
                        //     userDetails && userDetails.email
                        //   }&OrgName=${
                        //     userDetails?.uData?.did || data?.did
                        //   }&sessID=${data?.sData?.sid}&userId=${
                        //     userDetails?.uData?.ur_id
                        //   }&SessionType=${type}&Session_Date=${moment().format(
                        //     "DD MMM YYYY"
                        //   )}&ProgramID=${data?.sData?.msid}`,
                        //   "_blank"
                        // );
                      }}
                    >
                      Feedback
                    </CustomButton>
                  )}
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "column-reverse", md: "row" }}
                spacing={{ xs: 1, md: 4 }}
              >
                <Paper
                  elevation={5}
                  sx={{
                    p: 3,
                    borderRadius: "4px",
                    textAlign: "justify",
                    width: "100%",
                  }}
                >
                  <Stack direction={{ xs: "column", sm: "column", md: "row" }}>
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      sx={{ flexGrow: 1 }}
                    >
                      {data?.sData?.startdate && (
                        <HeadingSecondary>
                          Start date & time ;
                          <span style={{ marginLeft: "5px" }}></span>
                          {moment(parseInt(data?.sData?.startdate)).format(
                            "MMMM Do YYYY, h:mm A"
                          )}
                        </HeadingSecondary>
                      )}
                      {data?.sData?.enddate && (
                        <HeadingSecondary>
                          End date & time &#58;
                          <span style={{ marginLeft: "5px" }}></span>
                          {moment(parseInt(data?.sData?.enddate)).format(
                            "MMMM Do YYYY, h:mm A"
                          )}
                        </HeadingSecondary>
                      )}

                      {data?.sData?.date_list && (
                        <table className="tableStyle">
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: "top" }}>
                                <HeadingSecondary>
                                  Session Date & Time :
                                </HeadingSecondary>
                              </td>

                              {data?.sData?.date_list[0]?.combineStartTime !==
                                undefined &&
                                data?.sData?.date_list?.map((item) => {
                                  return (
                                    <tr>
                                      <td>
                                        {moment(item.combineStartTime).format(
                                          "DD/MM/YYYY"
                                        )}
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                        ;
                                        {moment(item.combineStartTime).format(
                                          "LT"
                                        )}
                                        <span
                                          style={{ marginLeft: "2px" }}
                                        ></span>
                                        -
                                        <span
                                          style={{ marginLeft: "2px" }}
                                        ></span>
                                        {moment(item.combineEndTime).format(
                                          "LT"
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              {data?.sData?.date_list[0]?.combineStartTime ===
                                undefined && <td>{"yet to be scheduled"}</td>}
                            </tr>
                          </tbody>
                        </table>
                      )}
                      {data?.sData?.agenda && (
                        <Button
                          variant="contained"
                          sx={StyledButtons}
                          onClick={() => handleClickCertificateOpen()}
                        >
                          Agenda
                        </Button>
                      )}
                    </Stack>
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      sx={{ flexGrow: 1 }}
                    >
                      <table id="liveSession">
                        <tbody>
                          <tr>
                            <td style={{ width: "180px" }}>
                              {data?.sData?.speaker_type === null ? (
                                "Speaker"
                              ) : (
                                <span style={{ textTransform: "capitalize" }}>
                                  {data?.sData?.speaker_type}
                                </span>
                              )}
                              &#58;
                            </td>
                            <td>
                              <Link
                                to={`/session?sid=${query.get("sid")}`}
                                onClick={async () => {
                                  handleClickSpeakerOpen(
                                    data?.sData?.speakers[0]
                                  );
                                }}
                              >
                                {data?.sData?.speakers?.length !== 0 &&
                                  data?.sData?.speakers[0].name}
                              </Link>
                            </td>
                          </tr>
                          {data?.sData?.speakers.map((speaker, idx) => {
                            let rightSpeaker = [];
                            if (idx !== 0) {
                              rightSpeaker.push(speaker);
                            }
                            return (
                              <React.Fragment key={idx + "sdfjfgfdg"}>
                                {rightSpeaker.map((sp, id) => (
                                  <tr key={id + "prjwal"}>
                                    <td></td>
                                    <td>
                                      <Link
                                        to={`/session?sid=${query.get("sid")}`}
                                        onClick={() => {
                                          handleClickSpeakerOpen(sp);
                                        }}
                                      >
                                        {sp.name}
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            );
                          })}
                          {(data?.sData?.priceinr > 0 ||
                            data?.sData?.priceusd) > 0 &&
                            (data?.sData?.paid === true ||
                              data?.sData?.paid === "true") && (
                              <tr>
                                <td>Fees &#58;</td>
                                <td>
                                  <p>
                                    INR {data?.sData?.priceinr} (Inc. of GST){" "}
                                    <Box
                                      component="span"
                                      sx={{ mx: 0.3 }}
                                    ></Box>
                                    <span>
                                      {data?.sData?.priceusd > 0 && (
                                        <>
                                          /{" "}
                                          <Box
                                            component="span"
                                            sx={{ mx: 0.3 }}
                                          >
                                            {" "}
                                            USD{" "}
                                          </Box>
                                          {data?.sData?.priceusd ?? ""}
                                        </>
                                      )}
                                    </span>
                                  </p>
                                </td>
                              </tr>
                            )}

                          {data?.sData?.earlydate &&
                            (data?.sData?.paid === true ||
                              data?.sData?.paid === "true") && (
                              <tr>
                                <td>Early Bird Offer &#58;</td>
                                <td>
                                  <p>
                                    INR {data?.sData?.earlypriceinr ?? "0"}
                                    <Box
                                      component="span"
                                      sx={{ mx: 0.3 }}
                                    ></Box>
                                    (Inc. of GST)
                                    {data?.sData?.earlypriceusd > 0 && (
                                      <>
                                        /{" "}
                                        <Box component="span" sx={{ mx: 0.3 }}>
                                          {" "}
                                          USD{" "}
                                        </Box>
                                        {data?.sData?.earlypriceusd ?? "0"} till
                                      </>
                                    )}
                                    <Box
                                      component="span"
                                      sx={{ mx: 0.3 }}
                                    ></Box>
                                    {moment(
                                      parseInt(data?.sData?.earlydate)
                                    ).format("DD MMM YYYY")}
                                  </p>
                                </td>
                              </tr>
                            )}
                          {data?.sData?.perdis !== null &&
                            data?.sData?.perdis > 0 &&
                            (data?.sData?.paid === true ||
                              data?.sData?.paid === "true") && (
                              <tr>
                                <td>Percentage Discount &#58;</td>
                                <td>
                                  <p>{data?.sData?.perdis} %</p>
                                </td>
                              </tr>
                            )}
                          {data?.sData?.perdis > 0 && (
                            <tr>
                              <td>Price after Discount &#58;</td>
                              <td>
                                <p>
                                  INR {data?.sData?.priceafterinr ?? "0"} (Inc.
                                  of GST) /{" "}
                                  <Box component="span" sx={{ mx: 0.3 }}>
                                    {" "}
                                    USD{" "}
                                  </Box>
                                  {data?.sData?.priceafterusd ?? "0"}{" "}
                                </p>
                              </td>
                            </tr>
                          )}
                          {data?.sData?.stype !== null &&
                            (data?.sData?.stype === 2 ||
                              data?.sData?.stype === 3) &&
                            data?.sData?.location_value !== null &&
                            data?.sData?.location_value !== "null" &&
                            data?.sData?.location_value !== "" && (
                              <tr>
                                <td>location &#58;</td>
                                <td>
                                  <p>{data?.sData?.location_value}</p>
                                </td>
                              </tr>
                            )}
                          {data?.sData?.stype !== null &&
                            data?.sData?.note !== "null" &&
                            data?.sData?.note !== "" && (
                              <tr>
                                <td>Note &#58;</td>
                                <td>
                                  <p>{data?.sData?.note}</p>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {sessionJoin && data?.sData.stype !== 2 && (
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <LoadingButton
                          variant="contained"
                          loading={joinButtonLoader ? true : false}
                          sx={StyledButtonJoin}
                          disabled={gray ? true : false}
                          onClick={() => {
                            handleJoinSession();
                          }}
                        >
                          Join live session
                        </LoadingButton>
                        {gray &&
                          data?.uData?.aprstatus !== 1 &&
                          data?.uData?.pstatus !== 2 &&
                          data?.uData?.pstatus !== 5 &&
                          (data?.sData?.paid === "true" ||
                            data?.sData?.paid === true) && (
                            <HeadingSecondary>
                              You will be able to join the session once the
                              admin approves you.
                            </HeadingSecondary>
                          )}

                        {gray &&
                          data?.uData?.aprstatus !== 1 &&
                          data?.sData?.paylater === true &&
                          data?.uData?.pstatus === 2 &&
                          (data?.sData?.paid === "true" ||
                            data?.sData?.paid === true) && (
                            <HeadingSecondary>
                              Pay within{" "}
                              {moment(parseInt(data?.sData?.pay_within)).format(
                                "DD/MM/YYYY"
                              )}{" "}
                              for admin to approve you
                            </HeadingSecondary>
                          )}
                        {(data?.sData?.paid === "false" ||
                          data?.sData?.paid === false) && (
                          <HeadingSecondary>
                            Complementary session
                          </HeadingSecondary>
                        )}
                        {data?.uData?.aprstatus === 1 &&
                          data?.sData?.web_id &&
                          data?.sData?.web_pass && (
                            <>
                              <HeadingSecondary style={{ cursor: "copy" }}>
                                Meeting ID : {data?.sData?.web_id}
                              </HeadingSecondary>
                              <HeadingSecondary style={{ cursor: "copy" }}>
                                Password : {data?.sData?.web_pass}
                              </HeadingSecondary>
                            </>
                          )}
                      </Stack>
                    )}
                    {Object.keys(data?.uData).length > 0 &&
                      data?.uData.u_type === 0 &&
                      !regButton && (
                        <HeadingSecondary>
                          You have expressed interest, you will be notified when
                          registration starts
                        </HeadingSecondary>
                      )}
                    {expressInterest && (
                      <LoadingButton
                        loading={expressInterestLoader ? true : false}
                        disabled={expressInterestLoader ? true : false}
                        variant="contained"
                        sx={StyledButtons}
                        onClick={() => handleExpressInterest()}
                      >
                        Express interest
                      </LoadingButton>
                    )}

                    <Stack direction="column" rowGap={2}>
                      {regButton && (
                        <Stack direction="column" alignItems="center">
                          <LoadingButton
                            loading={checkSessionLimitLoader ? true : false}
                            disabled={checkSessionLimitLoader ? true : false}
                            variant="contained"
                            sx={StyledButtons}
                            onClick={() => {
                              if (paylater) {
                                setRegOpen(true);
                              } else {
                                checkForSlots();
                              }
                            }}
                          >
                            Register
                          </LoadingButton>

                          <HeadingThree>
                            Register before the session starts.
                          </HeadingThree>
                        </Stack>
                      )}
                      {paylater && (
                        <Stack direction="column" alignItems="center">
                          <LoadingButton
                            loading={checkSessionLimitLoader ? true : false}
                            disabled={checkSessionLimitLoader ? true : false}
                            variant="contained"
                            sx={StyledButtons}
                            onClick={() => {
                              checkForSlots();
                            }}
                          >
                            Pay
                          </LoadingButton>
                        </Stack>
                      )}
                      {invoiceOpenNew && (
                        <Stack direction="column" alignItems="center">
                          <LoadingButton
                            loading={checkSessionLimitLoader ? true : false}
                            disabled={checkSessionLimitLoader ? true : false}
                            variant="contained"
                            sx={StyledButtons}
                            onClick={() => {
                              setInvoiceOpen(true);
                            }}
                          >
                            View Invoice
                          </LoadingButton>
                          {invoiceOpenNew && !paylater && (
                            <HeadingThree>
                              You are requseted for fee concession.You will be
                              notified once the admin approve your payment
                              details.
                            </HeadingThree>
                          )}
                        </Stack>
                      )}
                      {data?.uData.u_type === 2 && (
                        <HeadingThree>
                          You are now added to the waiting list.You will be
                          notified once the slots are available
                        </HeadingThree>
                      )}
                    </Stack>
                    {sessionCompleted && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{ mt: 1 }}
                      >
                        {certificate && (
                          <Button
                            variant="contained"
                            sx={StyledButtons}
                            onClick={() => handleClickCertificateOpen()}
                          >
                            View certificate
                          </Button>
                        )}
                        {slides && (
                          <Button
                            variant="contained"
                            sx={StyledButtons}
                            onClick={() => handleClickSlidesOpen()}
                          >
                            Summary slides
                          </Button>
                        )}

                        {!certificate &&
                          (data?.feedback || query.get("feedback")) && (
                            <HeadingThree>
                              Admin should mark attendance to view certificate
                            </HeadingThree>
                          )}
                      </Stack>
                    )}
                  </Stack>
                  {sessionCompleted && (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {!(query.get("feedback") || data?.feedback) && (
                        <HeadingThree>
                          Fill out feedback form to
                          <span style={{ display: "block" }}>
                            {" "}
                            View summary slide for this session.
                          </span>
                        </HeadingThree>
                      )}
                    </Stack>
                  )}
                </Paper>

                <img
                  src={`https://${
                    config.aws_cloudfront_url
                  }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                    data?.sData?.timgname
                  }`}
                  alt=""
                  height="200"
                  width="auto"
                  style={{ borderRadius: "4px" }}
                />
              </Stack>

              <>
                <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
                  {data?.sData?.sdesc !== "null" &&
                    data?.sData?.sdesc !== "" &&
                    data?.sData?.sdesc !== null && (
                      <>
                        <HeadingSecondary>Overview</HeadingSecondary>
                        <p>{data?.sData?.sdesc}</p>
                      </>
                    )}

                  {data?.sData?.lobj !== "null" &&
                    data?.sData?.lobj !== "" &&
                    data?.sData?.lobj !== null && (
                      <>
                        <HeadingSecondary style={{ marginTop: "10px" }}>
                          Learning objectives :{" "}
                        </HeadingSecondary>

                        <ul>
                          {data?.sData?.lobj
                            ?.split("\n")
                            .map(
                              (obj, idx) =>
                                obj.replace(/\s/g, "") !== "" && (
                                  <li key={idx + "sddgd"}>{obj}</li>
                                )
                            )}
                        </ul>
                      </>
                    )}

                  {data?.sData?.taudi !== "null" &&
                    data?.sData?.taudi !== "" &&
                    data?.sData?.taudi !== null && (
                      <>
                        <HeadingSecondary style={{ marginTop: "10px" }}>
                          Target audience:{" "}
                        </HeadingSecondary>
                        <ul>
                          {data?.sData?.taudi
                            ?.split("\n")
                            .map(
                              (obj, id) =>
                                obj.replace(/\s/g, "") !== "" && (
                                  <li key={id + "sdf"}>{obj}</li>
                                )
                            )}
                        </ul>
                      </>
                    )}
                </Paper>
              </>
            </Stack>
            {regOpen && (
              <Dialog
                open={regOpen}
                aria-labelledby="responsive-dialog-title"
                BackdropProps={{
                  sx: {
                    backgroundColor: Theme.backDropOpacity,
                  },
                }}
                scroll="paper"
                maxWidth="lg"
              >
                <RegisterFrom
                  handleClose={handleClose}
                  sessionData={data?.sData}
                  uData={data?.uData}
                  setRegButton={setRegButton}
                  regButton={regButton}
                  setSessionJoin={setSessionJoin}
                  refetch={refetch}
                  regData={data?.regidtls ?? {}}
                  paylater={paylater}
                  invoiceData={viewingState}
                />
              </Dialog>
            )}
            {feedbackFormOpen && (
              <Dialog
                open={feedbackFormOpen}
                aria-labelledby="responsive-dialog-title"
                BackdropProps={{
                  sx: {
                    backgroundColor: Theme.backDropOpacity,
                  },
                }}
                scroll="paper"
                maxWidth="lg"
              >
                <USPFeedback
                  handleClose={feedbackFormClose}
                  sid={fdata.sid}
                  type={fdata.type}
                  ur_id={fdata.ur_id}
                  refetch={refetch}
                />
              </Dialog>
            )}

            {loginOpen && sessionData.preLogin && (
              <SignInAndSignUp
                loginOpen={loginOpen}
                setLoginOpen={setLoginOpen}
                onlySingUp={onlySignUp}
              />
            )}
            {openSpeaker && (
              <SpeakerDialog
                open={openSpeaker}
                setOpen={setSpeaker}
                speakerDetails={speakerDetails}
              />
            )}
            {certificateOpen && (
              <Dialog
                open={certificateOpen}
                aria-labelledby="responsive-dialog-title-certificateOpen"
                scroll="paper"
                maxWidth="xl"
              >
                <CertificatePopUp
                  open={certificateOpen}
                  setOpen={setCertificateOpen}
                  details={data?.uData}
                  sData={data?.sData}
                />
              </Dialog>
            )}
            {slideOpen && (
              <SlidesPopUp
                open={slideOpen}
                setOpen={setSlideOpen}
                details={data?.sData}
              />
            )}
          </>
        )}
      {data !== undefined && data?.sData !== undefined && (
        <>
          {Object.keys(data?.sData).length === 0 && (
            <div
              style={{
                height: "90vh",
                width: "100vw",
                display: "grid",
                placeItems: "center",
              }}
            >
              <div
                style={{
                  width: "400px",
                  height: "200px",
                  background: "#1974b5",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: ".8rem",
                }}
              >
                <div style={{ margin: "2rem 0", textAlign: "center" }}>
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    Content you are looking for could not be found
                  </p>
                  <button
                    style={{
                      border: "none",
                      color: "#1974b5",
                      background: "#fff",
                      padding: ".5rem 2rem",
                      marginTop: "2rem",
                      borderRadius: ".4rem",
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => history.push("/dashboard")}
                  >
                    Go to dashboard
                  </button>
                </div>
                <div
                  style={{
                    marginBottom: "2rem",
                    color: "#fff",
                  }}
                >
                  <span style={{ marginRight: ".5rem" }}> Please contact</span>
                  <a href="https://www.usp.org/help">help@www.usp.org</a>
                  <span style={{ marginLeft: ".5rem" }}> for assistance</span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {invoiceOpen && (
        <Dialog
          fullScreen={fullScreen}
          open={invoiceOpen}
          aria-labelledby="responsive-dialog-title-2"
          maxWidth="lg"
        >
          <DialogTitle id="responsive-dialog-title">
            <IconButton
              onClick={() => {
                setInvoiceOpen(false);
                refetch();
              }}
              sx={{ position: "absolute", right: 10, top: 10 }}
            >
              <Close sx={{ fontSize: "2rem" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {viewingState.invoiceid === undefined ||
            viewingState.invoiceid === null ? (
              <div className={classes.container} id="invoicepdf">
                <div className={classes.header}>
                  <h2>USP Education</h2>
                  <div>
                    <img src={logo} alt="logo" height="80" />
                  </div>
                </div>
                <div className={classes.body}>
                  <p>
                    {moment(new Date(viewingState.date).getTime()).format(
                      "DD MMMM YYYY"
                    )}
                  </p>
                  <br />
                  <h4>Proforma Invoice</h4>
                  <br />
                  <span>
                    Bill to: -<br />
                    <span style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      {viewingState.address}
                    </span>
                    <br />
                    <br />
                    <span>GST: {viewingState.gstNo}</span>
                  </span>
                  <p style={{ margin: "2rem 0rem" }}>
                    Payment Terms: 100% Advance Payment
                  </p>
                  <div>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow sx={{ "th, td": { border: 1.5 } }}>
                            <TableCell>Line</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>HSN</TableCell>
                            <TableCell>Qty.</TableCell>

                            <TableCell>{`Unit(${viewingState.unit})`}</TableCell>
                            <TableCell>{`Amount(${viewingState.unit})`}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ "th, td": { border: 1.5 } }}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              1
                            </TableCell>
                            <TableCell>{`${viewingState.sn} on ${viewingState.sessionDate}`}</TableCell>
                            <TableCell align="center">999293</TableCell>
                            <TableCell align="center">1</TableCell>
                            <TableCell align="center">
                              {viewingState.price}
                            </TableCell>
                            <TableCell align="right">
                              {viewingState.price}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ "th, td": { border: 1.5 } }}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              2
                            </TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="right">
                              {viewingState.discount}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ "th, td": { border: 1.5 } }}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              3
                            </TableCell>
                            <TableCell>IGST @ 18%</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="right">
                              {viewingState.gst}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ "th, td": { border: 1.5 } }}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            ></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="right">
                              {calculateDiscountedPrice(
                                +viewingState?.price,
                                +viewingState?.gst,
                                +viewingState.discount
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div>
                    <p style={{ margin: "1rem 0rem", fontWeight: "700" }}>
                      {viewingState.unit}{" "}
                      {numberToWordsOnly(
                        calculateDiscountedPrice(
                          +viewingState?.price,
                          +viewingState?.gst,
                          viewingState?.discount
                        )
                      )}
                    </p>
                    <table>
                      <tr>
                        <td>USP India GSTIN</td>
                        <td>: 36AAACU7542C1ZI</td>
                      </tr>
                      <tr>
                        <td>USP India PAN No</td>
                        <td>: AAACU7542C</td>
                      </tr>
                    </table>

                    <p style={{ margin: "25px 0px" }}>
                      Nature of services: "Commercial Training and Coaching
                      Services".
                      <br />
                      Please inform us in advance if your unit is in SEZ for GST
                      amount.
                    </p>

                    <p>
                      Please Remit to:
                      <br />
                      Beneficiary: "United States Pharmacopeia India (P) Ltd."
                      <br />
                      Bank: ICICI Bank Ltd
                      <br />
                      Address: 6-2-1012, TGV Mansion, Khairtabad, Hyderabad.
                      <br />
                      Account: 000805005833
                      <br />
                      IFSC Code: ICIC0000008
                      <br />
                      Swift Code: ICICINBB008
                      <br />
                      Note: Taxes are subject to changes as per Government
                      rules.
                    </p>

                    <h4 style={{ textAlign: "center", margin: "3rem 0rem" }}>
                      This is an electronic document hence signature is not
                      required
                    </h4>
                  </div>
                </div>

                <div className={classes.footer}>
                  <h3>education.usp.org</h3>
                  <h3>Empowering a healthy tomorrow</h3>
                </div>
              </div>
            ) : (
              <div>
                {re.exec(viewingState.invoiceid)[1] !== undefined &&
                re.exec(viewingState.invoiceid)[1] === "pdf" ? (
                  <>
                    <iframe
                      src={`https://${
                        config.aws_cloudfront_url
                      }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${
                        viewingState.invoiceid
                      }`}
                      height="500"
                      width="750"
                    />
                  </>
                ) : (
                  <img
                    src={`https://${
                      config.aws_cloudfront_url
                    }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${
                      viewingState.invoiceid
                    }`}
                    alt={viewingState.invoiceid}
                    height="500"
                    width="750"
                  />
                )}
              </div>
            )}
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                color: Theme.color.primary,
                borderColor: Theme.color.primary,
                textTransform: "unset",
                "&:hover": {
                  color: Theme.color.primary,
                  borderColor: Theme.color.primary,
                  textTransform: "unset",
                },
              }}
              variant="outlined"
              onClick={() => downloadcode()}
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isLoading && (
        <Stack spacing={2} margin="3rem" paddingBottom="3rem">
          <Loader />
        </Stack>
      )}
    </Wrapper>
  );
};

function Loader() {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", gap: 3, mb: 3 }}>
        {" "}
        <Skeleton variant="rectangular" width="70vw" height={200} />
        <Skeleton variant="rectangular" width="30vw" height={200} />
      </Box>
      <Skeleton variant="rectangular" width="100%" height={200} />
    </Box>
  );
}
export default LiveSession;
