/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away-subtle.css";
import Backdrop from "@mui/material/Backdrop";
import { withStyles } from "@mui/styles";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  Button,
  CardMedia,
  Typography,
  Fade,
  CircularProgress,
  Modal,
  Box,
  FormControlLabel,
  Radio,
  Grid,
  RadioGroup,
  TextareaAutosize,
  Link,
  createStyles,
  Stack,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { API, JS } from "aws-amplify";
import ReactPlayer from "react-player";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import config, { Theme } from "../../config/aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useHistory } from "react-router-dom";
import TimerView from "../../components/CustomCircularProgress/TimerView";
import Rating from "@mui/material/Rating";
import { Offline, Online } from "react-detect-offline";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tippy from "@tippyjs/react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import downloadPdfSvg from "../../components/svg/downloadpdf.png";
import blackimg from "../../assets/media/black.png";
import { saveAs } from "file-saver";
import axios from "axios";
// import b64toBlob from 'b64-to-blob';
import { useLocation } from "react-router-dom";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { HeadingSecondary } from "../../components/Styles/Heading";
import CertificatePopUp from "../../pages/LiveSession/CertificatePopUp";
import "./Course.css";
import { Flag } from "@mui/icons-material";
// const useStyles = makeStyles((theme) => ({
//   modal: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     backgroundColor: "rgba(0, 0, 0, 0.73)",
//     paddingTop: "1%",
//   },
//   paper: {
//     backgroundColor: "#fff",
//     border: "2px solid #fff",
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3),
//     width: "1100px",
//     height: "660px",
//     borderRadius: ".8rem",
//     overflowX: "hidden",
//     overflowY: "auto",
//   },
//   paper1: {
//     backgroundColor: "#fff",
//     border: "2px solid #fff",
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3),
//     width: "400px",
//     borderRadius: ".8rem",
//   },
//   ratingroot: {
//     width: 300,
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     flexDirection: "column",
//     gap: "1.5rem",
//   },
//   quizroot: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   MuiGriditem: {
//     padding: "0 12px",
//   },
//   quizpaper: {
//     padding: theme.spacing(2),
//     textAlign: "center",
//     color: "#777",
//   },
//   certcover: {
//     height: "100%",
//   },
//   overlay: {
//     backgroundImage:
//       "linear-gradient(to right bottom, rgba(0,0,0,.2), rgba(0,0,0,.2))",
//     width: "1100px",
//     height: "660px",
//   },
//   label: {
//     "& .MuiTypography-body1": {
//       fontSize: "1.3rem",
//       color: "#555555",
//       fontWeight: "500",
//       overflow: "hidden !important",
//     },
//   },
//   labelSurvey: {
//     "& .MuiTypography-body1": {
//       fontSize: "1.25rem",
//       color: "#000",
//       fontWeight: "600",
//       overflow: "hidden !important",
//     },
//     "& .MuiTypography-body1::after": {
//       content: "*",
//       color: "red",
//     },
//   },
//   label2: {
//     "&.MuiFormControlLabel-root": {
//       paddingLeft: "30px",
//       display: "flex",
//       flexDirection: "column",
//     },
//     "&.MuiTypography-body1": {
//       fontSize: "1.3rem",
//     },
//   },
//   contentType: {
//     "& .PrivateSwitchBase-input-118": {
//       "& ::after": {
//         content: "hi !important",
//       },
//     },
//   },
// }));

// const styles = () =>
//   createStyles({
//     h: {
//       "&::before": {
//         content: '"some content"',
//         display: "block",
//         height: 60,
//         marginTop: -60,
//       },
//     },
//   });

// const GreenRadio = withStyles({
//   root: {
//     color: "#01498e",
//     overflow: "hidden",
//     "&$checked": {
//       color: "#01498e",
//     },
//   },
//   checked: {},
// })((props) => <Radio color="default" {...props} />);

export const StyledButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #01498e 90%, #01498e 30%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
    fontSize: "16px",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);
const StyledButtonQuiz = withStyles({
  root: {
    background: "linear-gradient(45deg, #01498e 90%, #01498e 30%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 50px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
    marginTop: "3rem",
    fontSize: "16px",
    marginBottom: "3rem",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export function TransitionsModal({
  setOpen,
  open,
  setCourseDetails,
  courseDetails,
  setCurNugget,
  setCurObject,
  setCurObjIndex,
  setCurNuggetId,
  setCurNugIndex,
  setCertificate,
  setProgress,
  setMarkComplete,
  setStartTime,
  setTpProgress,
  setcourseResume,
  updateCourseAnalytics,
}) {
  // const classes = useStyles();
  // const classesse = styles();

  const history = useHistory();
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [nextTopics, setNextTopics] = useState({});
  const scroll = useRef();
  const scrollT = useRef();

  useEffect(() => {
    //console.log(courseDetails.userdataset)
    
  }, []);
  const handleOpen = () => {
    if (
      courseDetails.prerequisites == false ||
      courseDetails.prerequisites === "false"
    ) {
      startcourse();
    } else if (
      (courseDetails.prerequisites === true ||
        courseDetails.prerequisites === "true") &&
      courseDetails.prerequisitescourses?.length > 0
    ) {
      let preflg = 0;
      if (courseDetails.comp_topics !== undefined) {
        for (let i = 0; i < courseDetails.prerequisitescourses.length; i++) {
          if (
            courseDetails.comp_topics !== undefined &&
            !courseDetails.comp_topics.includes(
              courseDetails.prerequisitescourses[i].tid
            )
          ) {
            nextTopics.title = courseDetails.prerequisitescourses[i].ttitle;
            nextTopics.tid = courseDetails.prerequisitescourses[i].tid;
            setNextTopics(nextTopics);
            preflg = 1;
            break;
          }
        }
      } else {
        nextTopics.title = courseDetails.prerequisitescourses[0].ttitle;
        nextTopics.tid = courseDetails.prerequisitescourses[0].tid;
        setNextTopics(nextTopics);
        preflg = 1;
      }
      if (courseDetails.prerequisitescourses === undefined && preflg === 0) {
        preflg = 1;
      }
      if (preflg === 0) {
        startcourse();
      } else {
        var ptag = "";
        if (
          courseDetails.prerequisitestype !== undefined &&
          courseDetails.prerequisitestype == "Required"
        ) {
          for (let i = 0; i < courseDetails.prerequisitescourses.length; i++) {
            ptag =
              ptag +
              '<p class="classtags">' +
              courseDetails.prerequisitescourses[i].ttitle +
              "</p>";
            if (i === courseDetails.prerequisitescourses.length - 1) {
              Swal.fire(
                {
                  html:
                    '<p class="classtags1"> Please complete the below prerequisite course in order to enable you to start ' +
                    courseDetails.ttitle +
                    "</p>" +
                    "<br><br>",
                  icon: `warning`,
                  showConfirmButton: true,
                  denyButtonText: nextTopics.title,
                  confirmButtonText: "Cancel",
                  confirmButtonClass: "confirmClass",
                  buttonsStyling: false,
                  showDenyButton: true,
                  customClass: {
                    cancelButton: "order-2 ",
                    confirmButton: "order-3",
                    denyButton: "order-1 coursebtn",
                  },
                },
                function () { }
              ).then((result) => {
                if (result.isConfirmed) {
                } else if (result.isDenied) {
                  console.log("nextTopics==", nextTopics.tid);
                  if (nextTopics.tid !== undefined) {
                    let breadcrumb = { ...userDetails };
                    breadcrumb.location = {
                      from: breadcrumb.location?.from,
                      courseName: nextTopics.title,
                      prevLoc: breadcrumb.location?.prevLoc,
                      programName: breadcrumb.location?.programName,
                      topicName: breadcrumb.location?.topicName,
                      topicId: breadcrumb.location?.topicId,
                      viewAll: breadcrumb.location?.viewAll,
                      programId: breadcrumb.location?.programId,
                      cid: breadcrumb.location?.cid,
                    };
                    console.log("breadcrumb==", breadcrumb.location);
                    dispatch(awsSignIn(breadcrumb));
                    history.push(`/course?id=${nextTopics.tid}`);
                    window.location.reload();
                  }
                }
              });
            }
          }
        } else if (
          courseDetails.prerequisitestype !== undefined &&
          courseDetails.prerequisitestype == "Suggestion"
        ) {
          for (let i = 0; i < courseDetails.prerequisitescourses.length; i++) {
            ptag =
              ptag +
              '<p class="classtags">' +
              courseDetails.prerequisitescourses[i].ttitle +
              "</p>";
            if (i === courseDetails.prerequisitescourses.length - 1) {
              Swal.fire({
                html:
                  ptag +
                  "</br>" +
                  "</br>" +
                  '<p style="color:#484848;">These are the recommended prerequisites for this course. Click "Cancel" below to start a prerequisite course, or click "OK to continue with this course.</p>',
                icon: `warning`,
                width: "400px",
                showCancelButton: true,
                cancelButtonText: "Cancel",
                showConfirmButton: true,
                confirmButtonText: "Ok",
                customClass: "sweetalert-lgs",
                confirmButtonClass: "confirmClass",
                buttonsStyling: false,
                cancelButtonClass: "cancelClass",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.value) {
                  syncUserProgress();
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                }
              });
            }
          }
        } else {
          startcourse();
        }
      }
    } else {
      startcourse();
    }
  };

  function startcourse() {
    Swal.fire({
      title: "Get started with the course?",
      showDenyButton: true,
      showCancelButton: false,
      reverseButtons: true,
      icon: `warning`,
      denyButtonText: `No`,
      confirmButtonText: `Yes`,
      allowOutsideClick: false,
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "confirmButton",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        syncUserProgress();
      } else if (result.isDenied) {
      }
    });
  }

  async function syncUserProgress() {
    //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;

    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        logintype: "Cognito",
        status: "new",
        key: courseDetails.tid,
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        version: courseDetails.version,
        tnuggets: courseDetails.noofnuggets,
        nav: courseDetails.freenavigation,
        email: userDetails.username,
        emailid: userDetails.email,
        tenant: userDetails.locale,
        pid: courseDetails.pid,
        vtid: courseDetails.vtid,
        tid: courseDetails.tid,
        sub_date: 1,
        ur_id: userDetails.uData.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    };

    try {
      console.log("bodyresponse===" + JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );

      updateLocalUserData(courseDetails);
      // setOpen(true);
      if (
        courseDetails.freenavigation == true ||
        courseDetails.freenavigation == "true"
      ) {
        // const bodyParam1 = {
        //   body: {
        //     oid: config.aws_org_id,
        //     eventtype: "Topic Completed",
        //     gender: "Unknown",
        //     logintype: "Cognito",
        //     topicid: courseDetails.tid,
        //     tn: courseDetails.ttitle,
        //     id: userDetails.id,
        //     iid: config.aws_cognito_identity_pool_id,
        //     version: courseDetails.version,
        //     tnuggets: courseDetails.noofnuggets,
        //     nav: courseDetails.freenavigation,
        //     email: userDetails.username,
        //     name: userDetails.name,
        //     emailid: userDetails.email,
        //     tenant: userDetails.locale,
        //     pid: courseDetails.pid,
        //   },
        //   headers: {
        //     "Content-Type": "application/json",
        //     Accept: "application/json",
        //     // Authorization: jwttoken,
        //   },
        // };
        // console.log("===analytic");
        // console.log(bodyParam1.body);
        // const response1 = await API.post(
        //   config.aws_cloud_logic_custom_name,
        //   "/analyticsWebApp",
        //   bodyParam1
        // );
        // updateCourseAnalytics("completed");
        // setOpen(false);
        // window.location.reload();
      } else {
        setOpen(true);
      }
      setLoading(false);
      history.push(`/course?id=${courseDetails.tid}`);
      // console.log(response);
      //window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }
  function updateLocalUserData(courseDetails) {
    courseDetails.userdataset.userdata = true;
    courseDetails.userdataset.sd = Math.round(new Date().getTime() / 1000);
    var tp = "0";
    if (courseDetails.noofnuggets > 0) {
      tp = "1";
      for (var w = 0; w < courseDetails.noofnuggets; w++) {
        if (w == 0) {
          tp = tp + "-" + "1";
        } else {
          tp = tp + "-" + "0";
        }
      }
    }
    courseDetails.userdataset.tp = tp;
    courseDetails.userdataset.cobj = 0;
    courseDetails.userdataset.cvid = 0;
    courseDetails.userdataset.cdoc = 0;
    courseDetails.userdataset.cfq = 0;
    courseDetails.userdataset.csq = 0;
    courseDetails.userdataset.casmt = 0;
    courseDetails.nresume = {};
    courseDetails.nresume.rnugget = courseDetails.nuggets[0].nid;
    courseDetails.nuggets[0].objects[0].op = 1;
    courseDetails.nresume.robject = 0;
    setTpProgress(tp);
    setCurNugget(courseDetails.nuggets[0]);
    setCurObject(courseDetails.nuggets[0].objects[0]);
    setCurObjIndex(0);
    setCurNuggetId(courseDetails.nuggets[0].nid);
    setCurNugIndex(0);
    setCertificate(false);
    setProgress(Number(courseDetails.nuggets[0].objects[0].oduration));
    setMarkComplete(false);
    setStartTime(new Date().getTime());
    console.log(courseDetails);
    setCourseDetails(courseDetails);
    setcourseResume(courseDetails.nresume);
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {loading && (
        // <CircularProgress
        //   className="spinnerStyle"
        //   style={{ position: "absolute !important", left: "50%", top: "50%" }}
        // />
        <></>
      )}
     
      <Button
        variant="contained"
        sx={{
          bgColor: Theme.color.secondary,
          "&:hover": {
            bgColor: Theme.color.secondary,
          },
        }}
        onClick={() => handleOpen()}
        disabled={
          courseDetails?.userdataset?.userprogress?.aprstatus === 1 &&
            courseDetails?.userdataset?.userprogress?.pstatus !== null
            ? false
            : true
        }
      >
        Start course
      </Button>
      <div>
        {courseDetails?.userdataset?.userprogress?.aprstatus === null &&
          courseDetails?.userdataset?.userprogress?.pstatus !== null &&
          "You will get access to the course once the admin approves you"}
      </div>
    </div>
  );
}
const defaultTippyProps = {
  // animation: "shift-away-subtle",
  // arrow: true,
  interactive: true,
  interactiveBorder: 0,
  placement: "bottom-end",
  delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 400,
  theme: "light",
  distance: 24,
};
export function ObjectPopUp({
  setOpen,
  open,
  setCourseDetails,
  courseDetails,
  curObject,
  setCurObject,
  curNugget,
  setCurNugget,
  curObjIndex,
  setCurObjIndex,
  curNuggetId,
  setCurNuggetId,
  progress,
  setProgress,
  markComplete,
  setMarkComplete,
  curNugIndex,
  setCurNugIndex,
  startTime,
  setStartTime,
  courseResume,
  setcourseResume,
  remaining,
  setRemaining,
  totalProgress,
  setTotalProgress,
  tpProgress,
  setTpProgress,
  perScore,
  setPerScore,
  totalScore,
  setTotalScore,
  showScore,
  setShowScore,
  btnClick,
  setBtnClick,
  loading,
  setLoading,
  certificate,
  setCertificate,
  updateCourseAnalytics,
  quizResponse,
  setQuizResponse,
  setQuizAnswers,
  quizAnswers,
  getTopicDetails
}) {
  // const classes = useStyles();

  // const classesse = styles();
  const scroll = useRef();
  const scrollT = useRef();
  const history = useHistory();
  let userDetails = useSelector(authData);

  const [quizQuestion, setQuizQuestion] = useState([]);
  const reactplayer = useRef(null);

  const [timerPalyPause, setTimerPalyPause] = useState("pause");
  const [objStatus, setObjStatus] = useState(false);

  const [tryBtnShow, setTryBtnShow] = useState(false);
  const [value, setValue] = React.useState("");
  const [ansShow, setAnsShow] = useState(false);

  const [scoreJson, setScoreJson] = useState({});

  const [certificateList, setCertificateList] = useState({});
  const [error, setError] = useState("");
  const [surveyError, setSurveyError] = useState("");

  const closePopUp = useRef(false);
  const retake = useRef(false);
  const nextCourseref = useRef(false);
  let questionsRef = useRef();
  let query = useQuery();
  const dispatch = useDispatch();
  const [viewAnswers, setViewAnswers] = useState(false);

  let src = `https://${config.aws_cloudfront_url
    }/${config.aws_org_id.toLowerCase()}-resources/certificate/`;
    useEffect(() => {
      //console.log(courseDetails.userdataset)
     
    }, []);
  const handleModalClose = () => {
    setViewAnswers(false);
    setTimerPalyPause("pause");
    setBtnClick(false);
    questionsRef.current = [];

    setQuizQuestion([]);
    if (
      curObject.otype != "quiz" &&
      curObject.otype != "feedback" &&
      curObject.op == 1 &&
      courseDetails.ctype == "enforced" &&
      progress > 0
    ) {
      setOpen(false);
      updateResumeTime();
    } else if (
      curObject.otype != "quiz" &&
      curObject.otype != "feedback" &&
      curObject.op == 1 &&
      courseDetails.ctype == "enforced" &&
      progress <= 0
    ) {
      closePopUp.current = true;
      syncUserProgress(1);
    } else if (
      curObject.otype != "quiz" &&
      curObject.otype != "feedback" &&
      curObject.op == 1 &&
      courseDetails.ctype == "regular"
    ) {
      closePopUp.current = true;
      syncUserProgress(1);
    } else if (curNugIndex === courseDetails.nuggets.length - 1) {
      if (nextCourseref.current === true) {
        if (
          courseDetails.certification === "true" ||
          courseDetails.certification === true
        ) {
          getUserCertificate();
        } else {
          nextcourse();
        }
        nextCourseref.current = false;
      } else {
        setOpen(false);
      }
      if (
        courseDetails.freenavigation === true ||
        courseDetails.freenavigation === "true"
      ) {
        nextcourse();
      } else {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  const handleModalOpen = (nresume) => {
    if (navigator.onLine === true) {
      for (var k = 0; k < courseDetails.nuggets.length; k++) {
        console.log(courseDetails.nuggets[k].nid + "==" + nresume.rnugget);
        let nflg = 0;
        for (var l = 0; l < courseDetails.nuggets[k].objects.length; l++) {
          if (courseDetails.nuggets[k].objects[l].op === 1) {
            setCurNugget(courseDetails.nuggets[k]);
            setCurObject(courseDetails.nuggets[k].objects[l]);
            setCurObjIndex(l);
            setCurNuggetId(courseDetails.nuggets[k].nid);
            setCurNugIndex(k);
            setMarkComplete(false);
            setStartTime(new Date().getTime());
            setProgress(Number(courseDetails.nuggets[k].objects[l].oduration));
            setOpen(true);
            nflg = 1;
            break;
          }
        }
        if (nflg === 1) {
          break;
        }
      }
    }
    //
  };

  function renderObjects() {
    let objType = curObject.otype;
    if (objType !== undefined) {
      switch (objType.toLowerCase()) {
        case "video":
          return VideoView(); // loadVideoView(curObject);
        case "audio":
          return AudioView(); // loadAudioView(curObject);
        case "pdf":
          return PdfView(); // loadMultimediaView(curObject);
        case "html":
          return WebView(); // loadMultimediaView(curObject);
        case "interactivity" || "Interactivity":
          return WebView(); // loadInteractivityView(curObject);
        case "scorm":
          return WebView(); // loadScormView(curObject);
        case "quiz":
          return tempView();
        case "feedback":
          return feedbackView();
        case "vimeo":
          return youtubeView(); // loadVimeoView(curObject);
        case "youtube":
          return youtubeView(); // loadYoutubeView(curObject);
        default:
          return null;
      }
    }
  }
  const playVideoHandler = () => {
    //   if(reactplayer.current !== null) {
    //     console.log('seeking to', 40)
    //     reactplayer.current.seekTo(120)
    // }

    if (courseDetails.ctype == "enforced" && curObject.op == 1) {
      setTimerPalyPause("play");
      console.log("play");
      if (objStatus === false) {
        setObjStatus(true);
      }

      //setTimerSeconds(timval)
      // timersec = setInterval(() => {
      //     console.log("sec=="+totalSec)
      //     var min =  Math.floor(totalSec /60) ;
      //     var sec =  Math.floor(totalSec % 60) ;
      //    let tsec = totalSec;
      //      tsec--;
      //     setTotalSec(tsec);
      //     setTimerMinuts(min);
      //     setTimerSeconds(sec);
      //     if(totalSec <= 0){
      //       //setMarkComplete(true)
      //       clearInterval(timersec);
      //     }
      //   }, 1000);
      //   return () => {
      //     clearInterval(timersec);
      //   };
    }
    if (curObject.op == 2) {
      setMarkComplete(false);
    }
  };
  const pauseVideoHandler = () => {
    setProgress(progress);
    setTimerPalyPause("pause");
  };
  const onSeekings = (param) => {
    console.log("onSeekings==" + param);
  };
  function VideoView() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          minHeight: "90%",
          position: "relative",
        }}
      >
        <ReactPlayer
          ref={(p) => {
            reactplayer.current = p;
          }}
          url={curObject.ourl}
          controls={true}
          width="100%"
          height="96%"
          onPlay={() => playVideoHandler()}
          onPause={() => pauseVideoHandler()}
          onSeek={(e) => onSeekings(e)}
          onDuration={() => {
            // alert(courseDetails.userdataset.tresume.time);
            if (
              curObject.op == 1 &&
              courseDetails.userdataset !== undefined &&
              courseDetails.userdataset.tresume !== undefined &&
              reactplayer.current !== null
            ) {
              if (courseDetails.userdataset.tresume.oid == curObject.oid) {
                reactplayer.current.seekTo(
                  courseDetails.userdataset.tresume.time
                );
                if (courseDetails.ctype == "enforced") {
                  setProgress(courseDetails.userdataset.tresume.obtime);
                }
              }
            }
          }}
          onBuffer={() => setMarkComplete(false)}
          onStart={() => setMarkComplete(false)}
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          onEnded={() => {
            console.log("end video");
            if (courseDetails.ctype == "regular" && curObject.op == 1) {
              setMarkComplete(true);
            } else if (curObject.op == 2) {
              setMarkComplete(true);
            } else if (courseDetails.ctype == "enforced" && curObject.op == 1) {
              setTimerPalyPause("play");
            }
          }}
        />
        <div
          className={markComplete && "overlay"}
          style={{ width: "100%", height: "100%", minHeight: "90%" }}
        ></div>
      </div>
    );
  }

  function AudioView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CardMedia
            image={curObject.ourl}
            ref={(p) => {
              reactplayer.current = p;
            }}
            height="100%"
            component="audio"
            frameBorder={0}
            onPlay={() => playVideoHandler()}
            onPause={() => pauseVideoHandler()}
            onSeek={(e) => onSeekings(e)}
            onEnded={() => {
              console.log("end video");
              if (courseDetails.ctype == "regular" && curObject.op == 1) {
                setMarkComplete(true);
              }
            }}
            controls
          />
          {/* <ReactPlayer
            url={curObject.ourl}
            controls={true}
            width='calc(100% - 15%)'
            height='calc(100% - 20px)'
            onPlay={() => playVideoHandler()}
            onPause={() => pauseVideoHandler()}
            onSeek={e => onSeekings(e)}
            onEnded={() => {
              console.log('end video');
              if (courseDetails.ctype == 'regular' && curObject.op == 1) {
                setMarkComplete(true);
              }
            }}
          /> */}
        </div>
      </div>
    );
  }

  function VimeoView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.url}
            controls={true}
            width="calc(100% - 15%)"
            height="calc(100% - 20px)"
            onPlay={() => playVideoHandler()}
            onPause={() => pauseVideoHandler()}
            onSeek={(e) => onSeekings(e)}
            onEnded={() => {
              console.log("end video");
              if (courseDetails.ctype == "regular" && curObject.op == 1) {
                setMarkComplete(true);
              }
            }}
          />
        </div>
      </div>
    );
  }

  function youtubeView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          height: "98%",
        }}
      >
        <CardMedia
          image={
            curObject.otype !== "vimeo"
              ? `https://www.youtube.com/embed/${curObject.ourl}?rel=0&showinfo=0`
              : `https://player.vimeo.com/video/${curObject.ourl}?title=0&loop=0`
          }
          height="100%"
          component="iframe"
          onLoad={onDocumentLoadSuccess()}
          frameBorder={0}
          controls
        />
      </div>
    );
  }

  function onDocumentLoadSuccess() {
    console.log(curObject);
    if (courseDetails.ctype == "regular" && curObject.op == 1) {
      setMarkComplete(true);
    } else if (courseDetails.ctype == "enforced" && curObject.op == 1) {
      if (timerPalyPause != "play") {
        setTimerPalyPause("play");
      }

      console.log("objStatus==" + objStatus);
      console.log("imerPalyPause==" + timerPalyPause);
      if (objStatus === false) {
        setObjStatus(true);
      }
    } else if (curObject.op == 2) {
      setMarkComplete(true);
    }
  }
  function PdfView() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          position: "relative",
          marginTop: "1rem",
        }}
      >
        <CardMedia
          alt="GeeksforGeeks"
          component="iframe"
          title={curObject.otitle}
          src={`${curObject.ourl}#toolbar=0&navpanes=0`}
          type="application/pdf"
          onLoad={onDocumentLoadSuccess()}
          frameBorder={0}
        />
        {/* <Document file={curObject.ourl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document> */}
        {/* <p>Page {pageNumber} of {numPages}</p> */}
      </div>
    );
  }

  function WebView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <CardMedia
          alt="GeeksforGeeks"
          component="iframe"
          title={curObject.otitle}
          src={curObject.ourl}
          onLoad={onDocumentLoadSuccess()}
          style={{
            marginTop: "10px",
            width: "100%",
            height: "92%",
            position: "absolute",
          }}
        />
        {/* <iframe
         src={curObject.url}
         width={"100%"}
         ref="iframe" 
         height={height - 300}
         title="WebView"
       /> */}
      </div>
    );
  }
  async function getQuiz(obj) {
    setLoading(true);
    const bodyParam = {
      body: {
        quizid: obj.oid,
        oid: config.aws_org_id,
        tid: query.get("id"),
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getQuiz",
        bodyParam
      );
      console.log(response);
      const { qitems, qtype } = response;
      // console.log("cutoff" + cutoff);
      let quizlist = [...qitems];
      if (retake.current === true) {
        quizlist = quizlist.sort(() => Math.random() - 0.5);
        setQuizQuestion(quizlist);
        // setRetakeExam(false);
        retake.current = false;
      } else {
        setQuizQuestion(qitems);
      }
      let tcurObject = { ...curObject };
      tcurObject.qtype = qtype;
      setCurObject(tcurObject);
      questionsRef.current = quizlist;
      setBtnClick(true);
      setLoading(false);
      if (qtype == 1 && curObject.otype != "feedback") {
        setTimerPalyPause("play");
      }
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  const handleChange = (event) => {
    let temp_state = [...quizQuestion];
    let rww = [...quizResponse];

    // 2. Make a shallow copy of the element you want to mutate
    let temp_element = { ...temp_state[event.target.name] };

    temp_element.score = 0;
    let index = rww.findIndex((item) => item.id == temp_element.iid);
    if (index != -1) {
      rww[index].response = event.target.value;
    } else {
      rww.push({ id: temp_element.iid, response: event.target.value });
    }
    for (var j = 0; j < temp_element.iopts.length; j++) {
      if (
        temp_element.iopts[j].content == event.target.value &&
        (temp_element.iopts[j].correct == "true" ||
          temp_element.iopts[j].correct == true)
      ) {
        temp_element.score = 1;
      }
    }
    // 3. Update the property you're interested in
    temp_element.value = event.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    temp_state[event.target.name] = temp_element;
    // 5. Set the state to our new copy

    setQuizQuestion(temp_state);
    questionsRef.current = temp_state;
    setQuizResponse(rww);
    console.log(rww);
    setValue(event.target.value);
  };
  function responseChecked(question, option) {
    const foundResponse = quizAnswers.some(
      (res) => res.id === question.iid && res.response === option.content
    );
    console.log(foundResponse);
    return foundResponse;
  }
  function responseSelected(question, option) {

    const foundResponse = quizAnswers.some(
      (res) => res.id === question.iid && res.response === option.content
    );

    if (
      (option.correct === "true" ||
        option.correct === true)) {
      return "greenborder";
    } else if (foundResponse &&
      (option.correct === "true" ||
        option.correct === true)) {
      return "greenborder";
    } else if (foundResponse &&
      (option.correct === "false" ||
        option.correct === false)) {
      return "redboreder";
    }

  }
  function tempView() {
    return (
      <div
        ref={scrollT}
        style={{
          display: "flex",
          flexDirection: "column",
          // gap: "2rem",
          overflowY: "auto",
          overflowX: "hidden",
          position: "relative",
          height: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "20rem",
          paddingBottom: "4rem",
        }}
      >
        {btnClick ? (
          <>
            {showScore ? (
              <>
                {" "}
                <div
                  style={{

                    width: "800px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        textTransform: "capitalize",
                        textAlign: "center",

                        fontWeight: "600",
                        marginBottom: "4rem",
                      }}
                    >
                      {courseDetails.pname}
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <a
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          if(quizAnswers.length == 0){
                            getQuizScore(curObject)
                          }
                          getQuiz(curObject); 
                          setViewAnswers(true);
                          setShowScore(false);
                          
                        }}
                      >
                        View Answers
                      </a>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          padding: "24px",
                          border: tryBtnShow
                            ? "1px solid red"
                            : "1px solid green",
                          width: "180px",
                          height: "155px",
                          borderRadius: "8px",
                          boxShadow: "1px 2px 2px 2px #f1f5f8",
                          backgroundColor: "#f1f5f8",
                          textAlign: "center",
                          display: "block",
                        }}
                        className="flex-center"
                      >
                        <h3
                          style={{
                            textTransform: "capitalize",
                            color: "#555555",
                            marginBottom: "1rem",
                          }}
                        >
                          Your score
                        </h3>
                        <h2 style={{ textTransform: "capitalize" }}>
                          {totalScore}
                        </h2>
                      </div>
                    </div>
                  </div>

                  {tryBtnShow ? (
                    <>
                      {/* <h3
                        style={{
                          marginTop: "2.5rem",
                          textAlign: "center",
                          color: "#555555",
                          fontSize: "1.75rem",
                        }}
                      >
                        We're sorry, but you did not achieve a passing score of
                        75% in order to earn the Certificate of Completion.
                        Please try againwww.
                      </h3>
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "15rem",
                        }}
                      >
                        <StyledButtonQuiz
                          style={{ left: "40%" }}
                          onClick={() => {
                            setMarkComplete(false);
                            // setRetakeExam(true);
                            retake.current = true;
                            getQuiz(curObject);
                            setShowScore(false);
                            setLoading(true);

                            questionsRef.current = [];
                            setProgress(Number(curObject.oduration));
                          }}
                        >
                          {" "}
                          Retake exam{" "}
                        </StyledButtonQuiz>
                      </div> */}
                    </>
                  ) : (
                    <div>
                      {curNugget?.objects[curObjIndex] !== undefined &&
                        courseDetails.nuggets[courseDetails.nuggets.length - 1]
                          .objects[
                          courseDetails.nuggets[
                            courseDetails.nuggets.length - 1
                          ].objects.length - 1
                        ].oid !== curNugget.objects[curObjIndex].oid && (
                          <>
                            <Typography
                              variant="h5"
                              style={{
                                textTransform: "capitalize",
                                textAlign: "center",
                                marginTop: "2.5rem",
                              }}
                            >
                              Please let us know the feedback by taking a short
                              survey below:
                            </Typography>
                          </>
                        )}
                      {loading ? (
                        <></>
                      ) : (
                        <div className="flex-center">
                          {curNugget.objects[curObjIndex + 1] == undefined ||
                            (curNugget.objects[curObjIndex + 1].op == 2 &&
                              (curObject.op == 2 ||
                                nextCourseref.current == true)) ? (
                            <Button
                              variant="contained"
                              sx={{
                                bgcolor: Theme.color.secondary,
                                textTransform: "unset",
                                marginTop: "10px"
                              }}
                              onClick={() => {
                                setTimerPalyPause("pause");
                                setLoading(true);
                                nextobject();
                              }}
                            >
                              {curNugget?.objects[curObjIndex] !== undefined &&
                                courseDetails.nuggets[
                                  courseDetails.nuggets.length - 1
                                ].objects[
                                  courseDetails.nuggets[
                                    courseDetails.nuggets.length - 1
                                  ].objects.length - 1
                                ].oid !== curNugget.objects[curObjIndex].oid ? (
                                <>Next</>
                              ) : (
                                <>Cancel</>
                              )}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              sx={{
                                bgcolor: Theme.color.secondary,
                                textTransform: "unset",
                              }}
                              onClick={() => {
                                questionsRef.current = [];
                                setTimerPalyPause("pause");
                                console.log("===take survery");
                                // setRetakeExam(false);
                                retake.current = false;
                                setLoading(true);
                                setBtnClick(true);
                                console.log(curNugget.objects[curObjIndex + 1]);
                                setQuizQuestion([]);
                                getQuiz(curNugget.objects[curObjIndex + 1]);
                                nextobject();
                              }}
                            >
                              {curNugget?.objects[curObjIndex] !== undefined &&
                                courseDetails.nuggets[
                                  courseDetails.nuggets.length - 1
                                ].objects[
                                  courseDetails.nuggets[
                                    courseDetails.nuggets.length - 1
                                  ].objects.length - 1
                                ].oid !== curNugget.objects[curObjIndex].oid ? (
                                <>Take survey</>
                              ) : (
                                <>Next</>
                              )}
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {loading ? (
                  <></>
                ) : (
                  <>
                    <div
                      style={{ width: "100%" }}
                      className="qradiobutton"
                    >
                      {!viewAnswers &&
                        quizQuestion.map((question, qind) => (
                          <div style={{ padding: "1rem 1rem 2rem 1rem" }}>
                            <h4
                              style={{
                                marginBottom: "2rem",
                                fontWeight: "600",
                                color: "rgb(0 1 6)",
                              }}
                            >
                              Q{qind + 1}. {question.istem}
                            </h4>
                            {question.imageurl !== undefined && (
                              <center>
                                <img
                                  style={{ width: "350px", margin: "10px" }}
                                  src={`https://${config.aws_cloudfront_url
                                    }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${courseDetails.tid
                                    }/${question.imageurl}`}
                                  alt="QuizImage"
                                />
                              </center>
                            )}
                            <RadioGroup
                              aria-label="gender"
                              name={qind}
                              value={question.value}
                              onChange={handleChange}
                            >
                              <div
                                // className={classes.quizroot}
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                              
                                {question.iopts.map((option) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      flex: "0 0 48%",
                                      padding: "0rem 3rem",
                                      height: "max-content",
                                      marginBottom: option.feedback
                                        ? "25px"
                                        : "",
                                    }}
                                    className={
                                      ansShow && question.value !== undefined
                                        ? option.correct === "true" ||
                                          option.correct === true
                                          ? "greenborder"
                                          : question.value == option.content &&
                                            (option.correct === "true" ||
                                              option.correct === true)
                                            ? "greenborder"
                                            : question.value == option.content &&
                                              (option.correct === "false" ||
                                                option.correct === false)
                                              ? "redboreder"
                                              : question.value == option.content
                                                ? "highlightborder"
                                                : "normalborder"
                                        : question.value == option.content
                                          ? "highlightborder"
                                          : "normalborder"
                                    }
                                  >
                                    
                                    <FormControlLabel
                                      value={option.content}
                                      dddfdfd
                                      control={
                                        <Radio
                                          style={{
                                            color: "black",
                                          }}
                                        />
                                      }
                                      label={option.content}
                                      key={option}
                                    // className={classes.label}
                                    ></FormControlLabel>
                                    {ansShow &&
                                      question.value !== undefined &&
                                      option.feedback != undefined && (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            flex: "0 0 48%",
                                            padding: "0rem 3rem",
                                            height: "max-content",
                                            // marginTop: "39px",
                                            // marginLeft: "-100px",
                                          }}
                                        >
                                          {option.feedback != undefined &&
                                            question.value ==
                                            option.content && (
                                              <p
                                                style={{ width: "100%" }}
                                              // className={classes.label}
                                              >
                                                Feedback : {option.feedback}
                                              </p>
                                            )}
                                        </div>
                                      )}
                                  </div>
                                ))}
                              </div>
                            </RadioGroup>
                          </div>
                        ))}
                      {error !== "" ? (
                        <h3
                          style={{
                            marginTop: "1rem",
                            color: "red",
                            fontSize: "1.6rem",
                            fontWeight: "600",
                            textAlign: "center",
                            alignSelf: "flex-start",
                          }}
                        >
                          {error}
                        </h3>
                      ) : null}
                      <div className="flex-center">
                        {!viewAnswers && (curObject.qtype == "1" || curObject.qtype == 1) &&
                          curObject.op == 1 ? (
                          <>
                            <Online>
                              <Button
                                variant="contained"
                                sx={{
                                  bgcolor: Theme.color.secondary,
                                  textTransform: "unset",
                                }}
                                onClick={() => {
                                  handleQuizSubmit();
                                }}
                              >
                                {" "}
                                Submit Summative
                              </Button>
                            </Online>
                            <Offline>
                              <StyledButtonQuiz>Submit</StyledButtonQuiz>
                            </Offline>
                          </>
                        ) : (
                          <>
                            {ansShow ? (
                              <>
                                <Offline>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      bgcolor: Theme.color.secondary,
                                      textTransform: "unset",
                                    }}
                                  >
                                    Next
                                  </Button>
                                </Offline>
                                <Online>
                                  {curObject.op === 1 ? (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        bgcolor: Theme.color.secondary,
                                        textTransform: "unset",
                                      }}
                                      onClick={() => {
                                        setAnsShow(true);
                                        syncUserProgress();
                                      }}
                                    >
                                      Next
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        bgcolor: Theme.color.secondary,
                                        textTransform: "unset",
                                      }}
                                      onClick={() => {
                                        setAnsShow(true);
                                        nextobject();
                                      }}
                                    >
                                      Next
                                    </Button>
                                  )}
                                </Online>
                              </>
                            ) : (
                              <>
                                {!viewAnswers ? (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      bgcolor: Theme.color.secondary,
                                      textTransform: "unset",
                                    }}
                                    onClick={() => {
                                      console.log(quizQuestion);
                                      setError("");
                                      validationcheck();
                                    }}
                                  >
                                    Submit
                                  </Button>
                                ) : null}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {viewAnswers ? (
              <div style={{ width: "100%" }} className="qradiobutton">
                {quizQuestion.map((question, qind) => (
                  <div style={{ padding: "1rem 1rem 2rem 1rem" }}>
                    <h4
                      style={{
                        marginBottom: "2rem",
                        fontWeight: "600",
                        color: "rgb(0 1 6)",
                      }}
                    >
                      Q{qind + 1}. {question.istem}
                    </h4>
                    {question.imageurl !== undefined && (
                      <center>
                        <img
                          style={{ width: "350px", margin: "10px" }}
                          src={`https://${config.aws_cloudfront_url
                            }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${courseDetails.tid
                            }/${question.imageurl}`}
                          alt="QuizImage"
                        />
                      </center>
                    )}
                    <RadioGroup
                      aria-label="gender"
                      name={qind}
                      value={question.value}
                      onChange={handleChange}
                    >
                      <div
                        // className={classes.quizroot}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        {question.iopts.map((option) => (
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              flex: "0 0 48%",
                              padding: "0rem 3rem",
                              height: "max-content",
                              marginBottom: option.feedback ? "25px" : "",
                            }}
                            className={
                              responseSelected(question, option)
                            }
                          >
                           
                            <FormControlLabel
                              value={option.content}
                              control={
                                <Radio
                                  style={{
                                    color: "black",
                                  }}
                                  checked={responseChecked(question, option)}
                                />
                              }
                              label={option.content}
                              key={option}
                            // className={classes.label}
                            ></FormControlLabel>
                            {ansShow &&
                              question.value !== undefined &&
                              option.feedback != undefined && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    flex: "0 0 48%",
                                    padding: "0rem 3rem",
                                    height: "max-content",
                                    // marginTop: "39px",
                                    // marginLeft: "-100px",
                                  }}
                                >
                                  {option.feedback != undefined &&
                                    question.value == option.content && (
                                      <p
                                        style={{ width: "100%" }}
                                      // className={classes.label}
                                      >
                                        Feedback : {option.feedback}
                                      </p>
                                    )}
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                ))}
              </div>
            ) : null}
            {viewAnswers ? (
              <div>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: Theme.color.secondary,
                    textTransform: "unset",
                  }}
                  onClick={() => {
                    setViewAnswers(false);
                    setShowScore(true);
                  }}
                >
                  <>Cancel</>
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <>
            {curObject.qtype == 1 ? (
              <>
                <h3
                  variant="h3"
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "600",
                    marginBottom: "5rem",
                  }}
                >
                  {courseDetails.pname}
                </h3>
                <div
                  style={{
                    display: "flex",

                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #777",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "200px",
                      borderRadius: "8px",
                      boxShadow: "1px 2px 2px 2px #f1f5f8",
                      backgroundColor: "#f1f5f8",
                      textAlign: "center",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        padding: "2rem",
                        borderRight: "1px solid #777",
                        flexGrow: "1",
                      }}
                    >
                      <p>
                        <HelpOutlineIcon style={{ fontSize: 30 }} />{" "}
                      </p>
                      <p>Questions</p>
                      <p>
                        {curObject.totalq
                          ? curObject.totalq
                          : curObject.oduration / 60}
                      </p>
                    </div>

                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h5">
                    All the best
                  </Typography>

                </div>
              </>
            ) : (
              <></>
            )}

            <div
              style={
                curObject.qtype === 1
                  ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }
                  : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "95%",
                  }
              }
            >
              {loading ? (
                <></>
              ) : (
                <div>
                  <Offline>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: Theme.color.secondary,
                        textTransform: "unset",
                      }}
                    >
                      Start Quiz
                    </Button>
                  </Offline>
                  <Online>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: Theme.color.secondary,
                        textTransform: "unset",
                      }}
                      onClick={() => {
                        setAnsShow(false);
                        getQuiz(curObject);
                        setShowScore(false);
                        setLoading(true);
                        setProgress(Number(curObject.oduration));
                        setError("");
                      }}
                    >
                      Start Quiz
                    </Button>
                  </Online>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
  function handlefeedbacktextChange(e, qind) {
    let temp_state = [...quizQuestion];

    let temp_element = { ...temp_state[qind] };
    temp_element.opindex = e.target.value;
    temp_state[qind] = temp_element;

    setQuizQuestion(temp_state);
    questionsRef.current = temp_state;
  }
  const handlefeedbackChange = (event) => {
    let temp_state = [...quizQuestion];

    // 2. Make a shallow copy of the element you want to mutate
    let temp_element = { ...temp_state[event.target.name] };

    // 3. Update the property you're interested in
    // alert(JSON.stringify(temp_element))
    temp_element.value = event.target.value;
    const index = temp_element.iopts.findIndex(
      (item) => item.content === event.target.value
    );
    if (index !== -1) {
      temp_element.opindex = index;
    }
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    temp_state[event.target.name] = temp_element;
    // 5. Set the state to our new copy

    setQuizQuestion(temp_state);
    questionsRef.current = temp_state;

    console.log(questionsRef.current);
    setValue(event.target.value);
  };
  function validationcheck() {
    for (var j = 0; j < questionsRef.current.length; j++) {
      if (questionsRef.current[j].score === undefined) {
        setError("Some questions are not answered , Please answer");
        // scrollT.current.scrollTo(0, 0);
        return false;
      }
    }
    setAnsShow(true);
  }
  function challenge(len) {
    if (len === 2) {
      return "challenge2";
    } else if (len === 3) {
      return "challenge3";
    } else if (len === 4) {
      return "challenge4";
    } else if (len === 5) {
      return "challenge5";
    }
  }

  function feedbackView() {
    return (
      <div
        ref={scroll}
        style={
          loading || quizQuestion.length == 0
            ? {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }
            : {
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              overflowY: "auto",
              overflowX: "hidden",
              position: "relative",
              height: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }
        }
      >
        {btnClick ? (
          <>
            <>
              {loading || quizQuestion.length == 0 ? (
                <></>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem",
                      justifyContent: "center",
                      marginBottom: "3rem",
                      width: "100%",
                    }}
                  >
                    {quizQuestion.map((question, qind) => (
                      <div
                        style={question.qtype == 3 ? { display: "flex" } : null}
                      >
                        <div style={{ height: "fit-content" }}>
                          <h4
                            style={{
                              marginBottom: "1rem",
                              fontWeight: "600",
                              color: "#555555",
                              display: "inline-block",
                              height:
                                question.istem.length > 100 ? "110px" : "",
                            }}
                          >
                            {qind + 1}. {question.istem}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h4>
                        </div>
                        <RadioGroup
                          aria-label="gender"
                          name={qind}
                          value={question.value}
                          onChange={handlefeedbackChange}
                        >
                          <div
                            // className={classes.quizroot}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            {question.qtype == 0 ? (
                              <div style={{ position: "relative" }}>
                                <div
                                  className={challenge(question.iopts.length)}
                                  style={{ zIndex: "2" }}
                                >
                                  {question.iopts.map((option, opind) => (
                                    <div>
                                      <FormControlLabel
                                        value={option.content}
                                        sxasas
                                        // control={<GreenRadio />}
                                        label={option.content}
                                        key={opind}
                                      // className={`${classes.label} ${classes.label2} ${classesse.h}`}
                                      />
                                    </div>
                                  ))}
                                </div>
                                {/* <div
                                  style={{
                                    borderTop: '1px solid #01498e',
                                    width: '85%',
                                    position: 'absolute',
                                    top: '40%',
                                    left: '8.6%',
                                    zIndex: '1',
                                  }}
                                ></div> */}
                              </div>
                            ) : (
                              <>
                                {question.qtype === 2 ? (
                                  <>
                                    <TextareaAutosize
                                      style={{
                                        width: "97%",
                                        height: "80px",
                                        backgroundColor: "transparent",
                                        resize: "none",
                                        padding: ".8rem 1.5rem",
                                        marginLeft: "2rem",
                                        outline: "none",
                                        border: "1px solid #01498e",
                                        borderRadius: ".5rem",
                                      }}
                                      rowsMax={2}
                                      aria-label="maximum height"
                                      placeholder={question.istem}
                                      value={question.response}
                                      onChange={(e) =>
                                        handlefeedbacktextChange(e, qind)
                                      }
                                      required
                                    />
                                  </>
                                ) : (
                                  <>
                                    {question.qtype === 3 ? (
                                      <select
                                        name="job"
                                        value={question.response}
                                        onChange={(event) => {
                                          let temp_state = [...quizQuestion];

                                          let temp_element = {
                                            ...temp_state[qind],
                                          };
                                          temp_element.opindex =
                                            event.target.value;
                                          temp_state[qind] = temp_element;
                                          setQuizQuestion(temp_state);
                                          handlefeedbacktextChange(event, qind);
                                        }}
                                        style={{
                                          marginLeft: "2rem",
                                          height: "25px",
                                          width: "200px",
                                        }}
                                        required
                                      >
                                        <option key="select" value="">
                                          select option
                                        </option>
                                        {question.iopts.map((e, key) => {
                                          return (
                                            <option key={key} value={e}>
                                              {e}cdscd
                                            </option>
                                          );
                                        })}
                                      </select>
                                    ) : (
                                      <Grid container spacing={5}>
                                        {question.iopts.map((option, opind) => (
                                          <Grid item xs={5}>
                                            <FormControlLabel
                                              value={option.content}
                                              ssss
                                              control={
                                                <Radio
                                                  style={{
                                                    color: "black",
                                                  }}
                                                  required
                                                />
                                              }
                                              label={option.content}
                                              key={opind}
                                            // className={classes.labelSurvey}
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </RadioGroup>
                      </div>
                    ))}
                    {surveyError !== "" ? (
                      <h3
                        style={{
                          color: "red",
                          fontWeight: "600",
                          fontSize: "1.6rem",
                          textAlign: "left",
                          justifySelf: "flex-start",
                          width: "100%",
                        }}
                      >
                        {surveyError}
                      </h3>
                    ) : null}
                    <div className="flex-center">
                      <Online>
                        <Button
                          variant="contained"
                          sx={{
                            bgcolor: Theme.color.secondary,
                            textTransform: "unset",
                          }}
                          onClick={() => {
                            setSurveyError("");
                            SurveyValidation();
                          }}
                        >
                          Next
                        </Button>
                      </Online>
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        ) : (
          <>
            <div>
              {loading ? (
                <></>
              ) : (
                <div className="centeritem" style={{ height: "63vh" }}>
                  <Offline>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: Theme.color.secondary,
                        textTransform: "unset",
                      }}
                    >
                      Take survey
                    </Button>
                  </Offline>
                  {curObject.op == 2 ? (
                    <>
                      <Typography variant="h2">Survey Completed</Typography>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: Theme.color.secondary,
                        textTransform: "unset",
                      }}
                      onClick={() => {
                        questionsRef.current = [];
                        setBtnClick(false);
                        // setRetakeExam(false);
                        retake.current = false;
                        getQuiz(curObject);
                        setShowScore(false);
                        setLoading(true);
                        setSurveyError("");
                      }}
                    >
                      Take survey
                    </Button>
                  )}
                  <Online></Online>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
  function handlemarkcomplete() {
    console.log("handlemarkcomplete===", reactplayer.current);
    syncUserProgress();
  }
  async function nextobject() {
    setQuizQuestion([]);
    questionsRef.current = [];
    setShowScore(false);
    setBtnClick(false);
    setLoading(true);
    let coindex = curObjIndex;
    coindex++;
    setCurObjIndex(coindex);
    setMarkComplete(false);
    if (coindex == curNugget.objects.length) {
      let curnugind = curNugIndex;
      setCurObjIndex(0);
      curnugind++;
      setCurNugIndex(curnugind);

      if (courseDetails.nuggets[curnugind] !== undefined) {
        setCurNugget(courseDetails.nuggets[curnugind]);
        //  if(courseDetails.nuggets[curnugind].objects[0].op == 0){
        //    courseDetails.nuggets[curnugind].objects[0].op = 1
        //  }

        setCurObject(courseDetails.nuggets[curnugind].objects[0]);
        if (
          courseDetails.nuggets[curnugind].objects[0].otype == "quiz" &&
          courseDetails.nuggets[curnugind].objects[0].op == 2 &&
          courseDetails.nuggets[curnugind].objects[0].qtype == 1
        ) {
          setLoading(true);
          getQuizScore(courseDetails.nuggets[curnugind].objects[0]);
        } else {
          setLoading(false);
        }
        if (
          courseDetails.ctype === "enforced" &&
          courseDetails.nuggets[curnugind].objects[0].op === 1
        ) {
          if (
            courseDetails.userdataset !== undefined &&
            courseDetails.userdataset.tresume !== undefined &&
            courseDetails.userdataset.tresume.oid ===
            courseDetails.nuggets[curnugind].objects[0].oid
          ) {
            setProgress(courseDetails.userdataset.tresume.obtime);
          } else {
            setProgress(
              Number(courseDetails.nuggets[curnugind].objects[0].oduration)
            );
          }

          if (
            courseDetails.nuggets[curnugind].objects[0].otype !== "video" &&
            courseDetails.nuggets[curnugind].objects[0].otype !== "quiz" &&
            courseDetails.nuggets[curnugind].objects[0].otype !== "feedback"
          ) {
            setTimerPalyPause("play");
          }
        }
      } else if (curnugind === courseDetails.nuggets.length) {
        if (nextCourseref.current === true) {
          if (
            courseDetails.certification === "true" ||
            courseDetails.certification === true
          ) {
            getUserCertificate();
          } else {
            nextcourse();
          }
          nextCourseref.current = false;
        } else {
          setOpen(false);
        }
        if (
          courseDetails.freenavigation === true ||
          courseDetails.freenavigation === "true"
        ) {
          nextcourse();
        } else {
          setOpen(false);
        }
      }
    } else {
      setCurObject(curNugget.objects[coindex]);
      if (
        curNugget.objects[coindex].otype === "feedback" &&
        curNugget.objects[coindex].op === 1
      ) {
        setBtnClick(true);
      }
      console.log(curNugget.objects[coindex]);
      if (
        courseDetails.ctype === "enforced" &&
        curNugget.objects[coindex].op === 1
      ) {
        if (
          courseDetails.userdataset !== undefined &&
          courseDetails.userdataset.tresume !== undefined &&
          courseDetails.userdataset.tresume.oid ===
          curNugget.objects[coindex].oid
        ) {
          setProgress(courseDetails.userdataset.tresume.obtime);
        } else {
          setProgress(Number(curNugget.objects[coindex].oduration));
        }

        if (
          curNugget.objects[coindex].otype !== "video" &&
          curNugget.objects[coindex].otype !== "quiz" &&
          curNugget.objects[coindex].otype !== "feedback"
        ) {
          setTimerPalyPause("play");
        }
      }
      if (
        curNugget.objects[coindex].otype === "quiz" &&
        curNugget.objects[coindex].op === 2 &&
        curNugget.objects[coindex].qtype === 1
      ) {
        setLoading(true);
        getQuizScore(curNugget.objects[coindex]);
      } else {
        setLoading(false);
      }
    }
    setLoading(false);
  }
  function nextcourse() {
    let nxtvar = "",
      btnmsg = "";
    if (
      courseDetails.nextcourse === true ||
      courseDetails.nextcourse === "true"
    ) {
      nxtvar = `Up next, ${courseDetails.nextcoursedata.ttitle}<br>`;
      btnmsg = "Click here to get started";
    } else {
      nxtvar = `To view some of our other courses,<br>`;
      btnmsg = "Please click here";
    }
    Swal.fire({
      title: "Congratulations!",
      html:
        `<h3 style="font-size:1.5625rem">You completed the course - ${courseDetails.ttitle} </h3>` +
        "<br >" +
        `<h3 style="font-size:1.5625rem">${nxtvar}</h3>`,
      showCloseButton: true,
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: `<h3 style="color:#0a3055;"> ${btnmsg} </h3>`,
      allowOutsideClick: false,
      customClass: "nextcourseswal",
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          courseDetails.nextcourse == true ||
          courseDetails.nextcourse == "true"
        ) {
          let breadcrumb = { ...userDetails };
          console.log(breadcrumb);

          breadcrumb.location = {
            from: breadcrumb.location.from,
            courseName: courseDetails.nextcoursedata.ttitle,
            prevLoc: breadcrumb.location.prevLoc,
            programName: breadcrumb.location.programName,
            topicName: breadcrumb.location.topicName,
            topicId: breadcrumb.location.topicId,
            viewAll: breadcrumb.location.viewAll,
            programId: breadcrumb.location.programId,
            cid: breadcrumb.location.cid,
          };
          dispatch(awsSignIn(breadcrumb));
          history.push(`/course?id=${courseDetails.nextcoursedata.tid}`);
        } else {
          history.push("/allCourses");
        }
        //syncUserProgress();
      } else if (result.isDenied) {
      }
    });
  }
  async function syncUserCertificate(otype) {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      setLoading(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          topicid: courseDetails.tid,
          ur_id: userDetails.uData.ur_id,
          id: userDetails.id,
          iid: config.aws_cognito_identity_pool_id,
          eid: userDetails.username,
          uname: userDetails.name,
          emailid: userDetails.emailid,
          tenant: userDetails.locale,
          pid: courseDetails.pid,
          completiondate: new Date().getDate(),
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwttoken,
        },
      };
      console.log("cert body response===" + JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/generateUserCertificate",
        bodyParam
      );

      console.log("cert==", response);

      //if (otype == "") {
      getUserCertificate();
      //}
    } catch (error) {
      console.error(error);
    }
  }
  async function getUserCertificate() {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      setLoading(true);
      setCertificate(true);
      const bodyParam = {
        body: {
          action: 0,
          oid: config.aws_org_id,
          tenant: userDetails.locale,
          topicid: courseDetails.tid,
          ur_id: userDetails.uData.ur_id,
          eid: userDetails.username,
          emailid: userDetails.email,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      console.log(
        "get user cert body response===" + JSON.stringify(bodyParam.body)
      );
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/listUserCerts",
        bodyParam
      );

      console.log(response);

      setCertificateList(response.certs);
      handleModalClose();
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  function SurveyValidation() {
    let surveyArr = [];
    for (var j = 0; j < questionsRef.current.length; j++) {
      if (
        questionsRef.current[j].opindex === undefined ||
        questionsRef.current[j].opindex === ""
      ) {
        setSurveyError("Some questions are not answered , Please answer");
        surveyArr.push(false);
      }
      surveyArr.push(true);
    }
    if (!surveyArr.includes(false)) {
      updateSurvey(curObject.oid);
      syncUserProgress();
    } else {
      // scroll.current.scrollTo(0, 0);
    }
  }
  async function updateSurvey(objid) {
    var obj = {};
    console.log(JSON.stringify(questionsRef.current));

    obj.survey = [];

    for (var l = 0; l < questionsRef.current.length; l++) {
      var robj = {};
      robj.iid = questionsRef.current[l].iid;
      robj.value = questionsRef.current[l].value;
      robj.opindex = questionsRef.current[l].opindex;
      robj.qtype = questionsRef.current[l].qtype;
      obj.survey.push(robj);

      if (l == questionsRef.current.length - 1) {
        try {
          //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
          setLoading(true);
          const bodyParam = {
            body: {
              oid: config.aws_org_id,
              topicid: courseDetails.tid,
              eid: userDetails.username,
              emailid: userDetails.email,
              tenant: userDetails.locale,
              pid: courseDetails.pid,
              objid,
              surveydata: obj,
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: jwttoken,
            },
          };
          console.log(
            "UserSurvey body response===" + JSON.stringify(bodyParam.body)
          );

          const response = await API.post(
            config.aws_cloud_logic_custom_name,
            "/updateUserSurvey",
            bodyParam
          );

          //console.log(response);
        } catch (error) {
          console.error(error);
        }
      }
    }
    //surveydata
  }
  async function syncUserProgress(type) {
    // const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
    let coindex = curObjIndex;
    coindex++;
    setLoading(true);
    setMarkComplete(false);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        status:
          courseDetails.freenavigation == "false" ||
            courseDetails.freenavigation == false
            ? "update"
            : "nav",

        tid: courseDetails.tid,
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        otype: curObject.otype,
        nugget: curNugIndex + 1,
        tnuggets: courseDetails.noofnuggets,
        nav:
          courseDetails.freenavigation == "false" ||
            courseDetails.freenavigation == false
            ? false
            : true,
        email: userDetails.username,
        emailid: userDetails.email,
        tenant: userDetails.locale,
        tobjects: curNugget.objects.length,
        object: coindex,
        pid: courseDetails.pid,
        ur_id: userDetails.uData.ur_id,
        obj_id: curObject.oid,
        op: 2,
        key: courseDetails.tid,
        obtime: (new Date().getTime() - startTime) / 1000,
        rmc: curNugget.ntitle,
        cert: courseDetails.certification,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    };
    if (type !== undefined) {
      bodyParam.body.op = 1;
    }
    try {
      console.log("syncUserDataWeb===" + JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      console.log(response, closePopUp);
      if (closePopUp.current === true) {
        setOpen(false);
        closePopUp.current = false;
      }
      console.log(response);
      let nrobj, prevobjtime, prevObject;
      if (type === undefined) {
        nrobj = courseResume;
        curNugget.objects[curObjIndex].op = 2;
        prevobjtime = curNugget.objects[curObjIndex].oduration;
        prevObject = curNugget.objects[curObjIndex].otype;
        setCurObjIndex(coindex);
        nrobj.robject = coindex;
        let ttp = totalProgress;
        ttp++;
        setTotalProgress(ttp);
      }

      setQuizQuestion([]);
      questionsRef.current = [];
      setTimerPalyPause("pause");
      setShowScore(false);
      setBtnClick(false);

      if (coindex == curNugget.objects.length && type === undefined) {
        let curnugind = curNugIndex;
        setCurObjIndex(0);
        nrobj.robject = 0;
        curnugind++;

        setCurNugIndex(curnugind);
        if (courseDetails.nuggets[curnugind] !== undefined) {
          setCurNugget(courseDetails.nuggets[curnugind]);

          nrobj.rnugget = courseDetails.nuggets[curnugind].nid;
          if (courseDetails.nuggets[curnugind].objects[0].op == 0) {
            courseDetails.nuggets[curnugind].objects[0].op = 1;
          }

          let rmtime = remaining;
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;
          setRemaining(rmtime);
          setCurObject(courseDetails.nuggets[curnugind].objects[0]);
          if (
            courseDetails.ctype == "enforced" &&
            courseDetails.nuggets[curnugind].objects[0].op == 1
          ) {
            setProgress(
              Number(courseDetails.nuggets[curnugind].objects[0].oduration)
            );

            setTimerPalyPause("play");
          }
          if (
            (courseDetails.ctype == "enforced" &&
              courseDetails.nuggets[curnugind].objects[0].op == 1 &&
              courseDetails.nuggets[curnugind].objects[0].otype == "video") ||
            courseDetails.nuggets[curnugind].objects[0].otype == "quiz"
          ) {
            setTimerPalyPause("pause");
          }
          setLoading(false);
        } else if (curnugind == courseDetails.nuggets.length) {
          // close popup
          let rmtime = remaining;
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;

          setRemaining(rmtime);
          updateCourseAnalytics("completed");
          var tp = "2";
          for (var d = 0; d < courseDetails.nuggets.length; d++) {
            tp = tp + "-" + "2";
          }
          setTpProgress(tp);
          if (
            prevObject != "quiz" &&
            (courseDetails.certification == "true" ||
              courseDetails.certification === true)
          ) {
            //console.log('certificate ===' + courseDetails.certification);
            syncUserCertificate("");
          } else if (
            prevObject == "quiz" &&
            (courseDetails.certification == "true" ||
              courseDetails.certification === true)
          ) {
            //console.log('certificate ===' + courseDetails.certification);
            nextCourseref.current = true;
            syncUserCertificate("quiz");
          } else {
            nextcourse();
            setOpen(false);
            setLoading(false);
          }
        }
      } else if (type === undefined) {
        if (curNugget.objects[coindex].op == 0) {
          curNugget.objects[coindex].op = 1;
        }
        let rmtime = remaining;
        rmtime.tremaining = rmtime.tremaining - prevobjtime;
        rmtime.mremaining = remaining.mremaining;
        setRemaining(rmtime);
        setCurObject(curNugget.objects[coindex]);
        if (
          courseDetails.ctype == "enforced" &&
          curNugget.objects[coindex].op == 1 &&
          curNugget.objects[coindex].otype != "feedback"
        ) {
          setProgress(Number(curNugget.objects[coindex].oduration));
          setTimerPalyPause("play");
        } else {
          setTimerPalyPause("pause");
        }

        if (curNugget.objects[coindex].otype == "feedback") {
          questionsRef.current = [];
          setQuizQuestion([]);
          getQuiz(curNugget.objects[coindex]);
          setLoading(true);
        } else {
          setLoading(false);
        }
      }
      if (type === undefined) {
        setcourseResume(nrobj);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  async function syncUserProgresswhilequiz() {
    try {
      questionsRef.current = [];

      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      let coindex = curObjIndex;
      coindex++;
      setLoading(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          status:
            courseDetails.freenavigation == "false" ||
              courseDetails.freenavigation == false
              ? "update"
              : "nav",
          key: courseDetails.tid,
          topicid: courseDetails.tid,
          id: userDetails.id,
          iid: config.aws_cognito_identity_pool_id,
          otype: curObject.otype,
          nugget: curNugIndex + 1,
          tnuggets: courseDetails.noofnuggets,
          nav:
            courseDetails.freenavigation == "false" ||
              courseDetails.freenavigation == false
              ? false
              : true,
          email: userDetails.username,
          emailid: userDetails.email,
          tenant: userDetails.locale,
          tobjects: curNugget.objects.length,
          object: coindex,
          pid: courseDetails.pid,
          cert: courseDetails.certification,
          ur_id: userDetails.uData.ur_id,
          obj_id: curObject.oid,
          op: 2,
          obtime: (new Date().getTime() - startTime) / 1000,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //Authorization: jwttoken,
        },
      };
      console.log("bodyresponse===" + JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );

      console.log("success===" + response);
      let nrobj = courseResume;
      curNugget.objects[curObjIndex].op = 2;
      let prevobjtime = curNugget.objects[curObjIndex].oduration;
      let prevObject = curNugget.objects[curObjIndex].otype;

      nrobj.robject = coindex;
      let ttp = totalProgress;
      ttp++;
      setTotalProgress(ttp);
      setMarkComplete(false);
      if (coindex == curNugget.objects.length) {
        let curnugind = curNugIndex;
        nrobj.robject = 0;
        curnugind++;

        if (courseDetails.nuggets[curnugind] !== undefined) {
          nrobj.rnugget = courseDetails.nuggets[curnugind].nid;
          if (courseDetails.nuggets[curnugind].objects[0].op == 0) {
            courseDetails.nuggets[curnugind].objects[0].op = 1;
          }
          let rmtime = remaining;
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;
          setRemaining(rmtime);
        } else if (curnugind == courseDetails.nuggets.length) {
          // close popup
          let rmtime = remaining;
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;

          setRemaining(rmtime);
          var tp = "2";
          for (var d = 0; d < courseDetails.nuggets.length; d++) {
            tp = tp + "-" + "2";
          }
          setTpProgress(tp);

          if (
            prevObject != "quiz" &&
            (courseDetails.certification == "true" ||
              courseDetails.certification === true)
          ) {
            //console.log('certificate ===' + courseDetails.certification);
            syncUserCertificate("");
            setOpen(false);
          } else if (
            prevObject == "quiz" &&
            (courseDetails.certification == "true" ||
              courseDetails.certification === true)
          ) {
            //console.log('certificate ===' + courseDetails.certification);

            nextCourseref.current = true;
            syncUserCertificate("quiz");
          } else {
            nextcourse();
            setOpen(false);
          }
          updateCourseAnalytics("completed");
        }
      } else {
        if (curNugget.objects[coindex].op == 0) {
          curNugget.objects[coindex].op = 1;
        }
        let rmtime = remaining;
        rmtime.tremaining = rmtime.tremaining - prevobjtime;
        rmtime.mremaining = remaining.mremaining;
        setRemaining(rmtime);
        console.log("feedback check ===" + curNugget.objects[coindex].otype);
      }
      setcourseResume(nrobj);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  function handleQuizSubmit() {
    let totalscore = 0;
    console.log(curObject);
    for (var j = 0; j < questionsRef.current.length; j++) {
      if (questionsRef.current[j].value == undefined) {
        setError("Some questions are not answered , Please answer");
        return;
      } else if (questionsRef.current[j].score !== undefined) {
        totalscore = totalscore + questionsRef.current[j].score;
      }

      if (j == questionsRef.current.length - 1) {
        let objsr = {};
        objsr.oid = curObject.oid;
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var date1 = date.getDate();
        var sdate = date1 + "." + month + "." + year;
        objsr.name = curObject.otitle;
        objsr.cdate = sdate;
        objsr.score = totalscore;
        objsr.tquestions = questionsRef.current.length;
        setScoreJson(objsr);

        setPerScore((totalscore / questionsRef.current.length) * 100);
        setTotalScore(totalscore);
        if ((totalscore / questionsRef.current.length) * 100 >= 75) {
          setShowScore(true);
          setMarkComplete(true);
          setTryBtnShow(false);
          handleScoreSubmit(objsr);
          syncUserProgresswhilequiz();
        } else if (curObject.qtype == "1") {
          setShowScore(true);
          setMarkComplete(true);
          setTryBtnShow(false);
          handleScoreSubmit(objsr);
          syncUserProgresswhilequiz();
        } else {
          handleScoreSubmit(objsr);
          setMarkComplete(true);
          setShowScore(true);
          setTryBtnShow(true);
        }
        setTimerPalyPause("pause");
        setError("");
      }
    }
  }
  async function handleScoreSubmit(scorejson) {
    //alert(JSON.stringify(scorejson))
    setLoading(true);
    const bodyParam = {
      body: {
        emailid: userDetails.email,
        pid: courseDetails.pid,
        key: courseDetails.tid,
        email: userDetails.username,
        oid: config.aws_org_id,
        uname: userDetails.name,
        tenant: userDetails.locale,
        scorejson,
        score: scorejson.score,
        totalqns: scorejson.tquestions,
        obj_id: curObject.oid,
        ur_id: userDetails.uData.ur_id,
        response: quizResponse,
        status: "quiz",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      console.log("quizscoresave====" + JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      console.log("updateSummativeQuiz==" + response);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  async function getQuizScore(objects) {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      setLoading(true);

      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tenant: userDetails.locale,
          eid: userDetails.username,
          emailid: userDetails.email,
          obj_id: objects.oid,
          ur_id: userDetails.uData.ur_id,
          tid: courseDetails.tid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      console.log("getscoreresponse===", bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getQuizScore",
        bodyParam
      );
      
      setQuizAnswers(response?.result);
      setLoading(false);
      setBtnClick(true);
      setPerScore(response?.score);
    } catch (error) {
      console.error(error);
    }
  }
  async function updateResumeTime() {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      var obj = {};
      obj.oid = curObject.oid;
      if (curObject.otype == "video" && reactplayer.current !== null) {
        obj.time = Math.round(reactplayer.current.getCurrentTime());
      }
      obj.obtime = progress;
      var dataset = courseDetails;
      dataset.userdataset.tresume = obj;
      setCourseDetails(dataset);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          obj_id: curObject.oid,
          op: 1,
          tid: courseDetails.tid,
          ur_id: userDetails.uData.ur_id,
          key: courseDetails.tid,
          obtime:
            obj.time !== undefined
              ? obj.time
              : (new Date().getTime() - startTime) / 1000,
          tenant: userDetails.locale,
          eid: userDetails.username,
          tresume: obj,
          nid: curNugget.nid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      console.log("==updateresume==", bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadPdf() {
    try {
      let filename,
        type,
        durl = "";
      if (curObject.otype == "pdf") {
        type = "application/pdf";
        filename = curObject.otitle + ".pdf";
        durl = curObject.ourl;
      }
      if (curObject.dtype !== undefined && curObject.dtype.includes("ppt")) {
        type = "application/pdf";
        filename = curObject.otitle + curObject.dtype;
        durl = curObject.durl;
      } else if (
        curObject.dtype !== undefined &&
        curObject.dtype.includes("doc")
      ) {
        type =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        filename = curObject.otitle + curObject.dtype;
        durl = curObject.durl;
      }
      axios
        .get(durl, {
          responseType: "blob",
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": config.DOMAIN,
            "Access-Control-Request-Headers": "Content-Type, Authorization",
            "Content-Type": type,
          },
        })
        .then((res) => {
          console.log(res);
          let file = new Blob([res.data], { type: type });
          saveAs(file, filename);
        });
    } catch (error) {
      console.log("errrr");
    }
  }
  function ImagetoPrint(source) {
    return (
      "<html><head><scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' /></body></html>"
    );
  }

  function PrintImage(source) {
    var Pagelink = "about:blank";
    var pwa = window.open(Pagelink, "_new");
    pwa.document.open();
    pwa.document.write(ImagetoPrint(source));
    pwa.document.close();
  }
  return (
    <div>
      <Offline>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={true}
        >
          {/* <Alert severity="warning" style={{ fontSize: "15px" }}>
            You are offline. Please check your internet connectivity.
          </Alert> */}
        </Snackbar>
      </Offline>
      {tpProgress.charAt(0) == 1 && courseDetails.freenavigation !== true ? (
        <>
          <Offline>
            <Button
              variant="contained"
              sx={{ bgcolor: Theme.color.secondary, textTransform: "unset" }}
            >
              Resume
            </Button>
          </Offline>
          <Online>
            <Button
              variant="contained"
              sx={{ bgcolor: Theme.color.secondary, textTransform: "unset" }}
              onClick={() => handleModalOpen(courseResume)}
            >
              Resume
            </Button>
          </Online>
        </>
      ) : (
        <></>
      )}
      {certificate && (
        <CertificatePopUp
          open={true}
          setOpen={setCertificate}
          sData={certificateList}
          name={
            userDetails?.uData?.first_name + " " + userDetails?.uData?.last_name
          }
          setIsLoading={setLoading}
          isLoading={loading}
          refetch={getTopicDetails}
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // className={classes.modal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.73)",
          paddingTop: "1%",
        }}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            // className={classes.paper}

            style={{
              position: "relative",
              overflowY: "hidden",
              backgroundColor: "#fff",
              border: "2px solid #fff",
              // boxShadow: theme.,
              padding: "2rem 2rem 3rem",
              width: "1100px",
              height: "660px",
              borderRadius: ".8rem",
              overflowX: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3
                id="transition-modal-title"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  fontWeight: "600",
                  marginBottom: "1rem",
                  width: "77%",
                }}
              >
                {certificate ? "" : curObject.otitle}
                {curObject.otype === "quiz" ? (
                  <>
                    {quizQuestion.length !== 0 && quizQuestion !== undefined ? (
                      <>
                        <span
                          style={{
                            height: "27px",
                            borderRight: "2px solid #333333",
                            marginLeft: "2rem",
                          }}
                        ></span>
                        <HelpOutlineRoundedIcon
                          style={{
                            fontSize: "2rem",
                            margin: ".4rem 0rem 0rem 2rem",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "1.5rem",
                            margin: ".4rem 0rem 0rem .3rem",
                            color: "#555555",
                          }}
                        >
                          {quizQuestion.length}Q
                        </span>
                      </>
                    ) : null}
                  </>
                ) : (
                  <></>
                )}

                {curObject.otype === "video" ? (
                  <Tippy
                    {...defaultTippyProps}
                    content={
                      <div
                        style={{
                          background: "transparent",
                          color: "#555555",
                          padding: ".5rem 1rem 1rem 1rem",
                          cursor: "pointer",
                          fontSize: "1.60rem",
                          fontWeight: "400",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: curObject.odescription
                            ? curObject.odescription
                            : "No description",
                        }}
                      ></div>
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          height: "25px",
                          borderRight: "2px solid #000",
                          marginLeft: "2rem",
                        }}
                      ></span>
                      <span
                        style={{
                          display: "inline-block",
                          height: "inherit",
                          width: "50px",
                          padding: "0 2rem",
                        }}
                      >
                        <InfoOutlinedIcon
                          className="icon"
                          style={{ display: "inline-block", color: "#1974b5" }}
                        />
                      </span>
                    </div>
                  </Tippy>
                ) : (
                  <>
                    {/* {curObject.otype === "pdf" ? (
                      <Link
                        onClick={() => downloadPdf()}
                        style={{
                          position: "absolute",
                          right: "216px",
                          top: "4%",
                          background: "transparent",
                          border: "none",
                          height: "2.3rem",
                          width: "2.3rem",
                          marginLeft: "2rem",
                          cursor: "pointer",
                          marginTop: ".5rem",
                          zIndex: "10000",
                        }}
                      >
                        {!certificate ? (
                          <img
                            src={downloadPdfSvg}
                            alt="download"
                            height="100%"
                          />
                        ) : (
                          ""
                        )}
                      </Link>
                    ) : null} */}
                  </>
                )}

                {courseDetails.ctype === "regular" ? (
                  <></>
                ) : (
                  <>
                    {loading ? (
                      <></>
                    ) : (
                      <>
                        {timerPalyPause === "play" &&
                          curObject.op === 1 &&
                          curObject.otype !== "feedback" ? (
                          <>
                            <TimerView
                              courseDetails={courseDetails}
                              curNugget={curNugget}
                              curObjIndex={curObjIndex}
                              setProgress={setProgress}
                              progress={progress}
                              timerPalyPause={timerPalyPause}
                              setTimerPalyPause={setTimerPalyPause}
                              objStatus={objStatus}
                              setMarkComplete={setMarkComplete}
                              handleQuizSubmit={handleQuizSubmit}
                              quizQuestion={quizQuestion}
                              setLoading={setLoading}
                              questionsRef={questionsRef}
                            ></TimerView>
                          </>
                        ) : (
                          <>
                            {curObject.op === 1 &&
                              curObject.otype !== "quiz" &&
                              curObject.otype !== "feedback" ? (
                              <>
                                <div className="timerdiv">
                                  {progress <= 0 ? (
                                    <></>
                                  ) : (
                                    <>
                                      <TimerOutlinedIcon
                                        style={{ fontSize: "2.3rem" }}
                                      />{" "}
                                      {`${Math.floor(
                                        progress / 60
                                      )}m : ${Math.floor(progress % 60)}s`}
                                    </>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </h3>
              <Typography></Typography>
              <button
                onClick={handleModalClose}
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  marginBottom: "15px",
                  marginRight: "-20px",
                }}
              >
                <CloseIcon fontSize="large" style={{ fontSize: "2.4rem" }} />
              </button>
            </div>

            {loading ? (
              <></>
            ) : (
              <>
                {certificate ? (
                  <>
                    {/* <CertificatePopUp
          open={true}
          setOpen={setCertificate}
          sData={certificateList}
          name={
            userDetails?.uData?.first_name + " " + userDetails?.uData?.last_name
          }
        /> */}
                    {/* <Button
                      variant="outlined"
                      style={{
                        fontSize: "1.5rem",
                        textAlign: "right",
                        marginRight: "20px",
                        position: "absolute",
                        right: "86px",
                        top: "8px",
                        color: "#fff",
                        background: "#01498e",
                      }}
                      onClick={() =>
                        PrintImage(
                          src +
                            certificateList?.tid +
                            "/" +
                            certificateList?.cname
                        )
                      }
                    >
                      {" "}
                      Print
                    </Button>

                    <button
                      style={{
                        background: "transparent",
                        border: "none",
                        height: "3rem",
                        width: "3rem",
                        cursor: "pointer",
                        position: "absolute",
                        right: "46px",
                        top: "13px",
                      }}
                    >
                      <a
                        href={`${
                          src +
                          certificateList?.tid +
                          "/" +
                          certificateList?.cname
                        }`}
                        download={userDetails.name}
                      >
                        <img
                          src={downloadPdfSvg}
                          alt="download"
                          height="100%"
                        />
                      </a>
                    </button>
                    <CardMedia
                      // className={classes.certcover}
                      image={
                        src +
                        certificateList?.tid +
                        "/" +
                        certificateList?.itemdetails?.title
                      }
                      title={certificateList?.itemdetails?.title}
                      component="img"
                      width={100}
                    /> */}
                  </>
                ) : (
                  <>
                    {markComplete && !loading && curObject.otype !== "quiz" ? (
                      <>
                        <Online>
                          <div>
                            <Button
                              style={{
                                position: "absolute",
                                boxShadow:
                                  "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                color: Theme.color.secondary,
                                textTransform: "unset",
                              }}
                              variant="outlined"
                              className="markcomplete  docmarkcombtn"
                              onClick={() => {
                                curObject.op === 1
                                  ? handlemarkcomplete()
                                  : nextobject();
                              }}
                            >
                              {" "}
                              Next
                            </Button>
                          </div>
                        </Online>
                        <Offline>
                          <Button
                            variant="outlined"
                            style={{
                              color: "#fff",
                              backgroundColor: Theme.color.secondary,
                              textTransform: "unset",
                            }}
                            className={
                              curObject.otype === "video"
                                ? "markcomplete"
                                : "markcomplete docmarkcombtn"
                            }
                          >
                            {" "}
                            Next{" "}
                          </Button>
                        </Offline>
                      </>
                    ) : (
                      <> </>
                    )}

                    {renderObjects()}
                  </>
                )}
              </>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
